import BaseClass from "./BaseClass";

export default class Client extends BaseClass {
    _categories
    _api
    _experiencesRequest
    _experiences

    /** @return Categories */
    get categories() { return this.propertyError() }
    /** @return Api */
    get api() { return this.propertyError() }
    get experiencesRequest() {return this.propertyError()}
    get experiences() {return this.propertyError()}
}