<template>
  <button
    class="iw-button"
    :class="{ 'iw-button--active': active }"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean
      }
    }
  };
</script>

<style lang="scss">
  .iw-button {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    padding: 0 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-main);
    // font-size: 14px;
    line-height: 1;
    background: #FFFFFF;
    border: 1px solid var(--color-903);
    border-radius: 5px;
    color: var(--color-903);
    white-space: nowrap;

    &--active,
    &:active {
      border-color: var(--color-901);
      background: var(--color-901);
      color: #FFFFFF;
    }
  }
</style>
