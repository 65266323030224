<template>
  <IconBase :icon-color="iconColor" view-box="0 0 24 24" icon-name="star" :width="width" :height="height">
      <path d="M13.73 3.50989L15.49 7.02989C15.73 7.51989 16.37 7.98989 16.91 8.07989L20.1 8.60989C22.14 8.94989 22.62 10.4299 21.15 11.8899L18.67 14.3699C18.25 14.7899 18.02 15.5999 18.15 16.1799L18.86 19.2499C19.42 21.6799 18.13 22.6199 15.98 21.3499L12.99 19.5799C12.45 19.2599 11.56 19.2599 11.01 19.5799L8.01997 21.3499C5.87997 22.6199 4.57997 21.6699 5.13997 19.2499L5.84997 16.1799C5.97997 15.5999 5.74997 14.7899 5.32997 14.3699L2.84997 11.8899C1.38997 10.4299 1.85997 8.94989 3.89997 8.60989L7.08997 8.07989C7.61997 7.98989 8.25997 7.51989 8.49997 7.02989L10.26 3.50989C11.22 1.59989 12.78 1.59989 13.73 3.50989Z" stroke="#707070" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "StarIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'none'
    }
  }
}
</script>

<style scoped>

</style>