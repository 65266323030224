<template>
  <div class="experience-card" v-if="Event" :style="{overflowY: showFullDesc ? 'hidden' : 'auto'}">
    <buttons-component v-if="!isMobile()">
      <button-component type="filter" @click="$emit('openFilter')" >
        <template #suffix><span v-if="isChangedFilter" class="change-icon" /></template>
      </button-component>
    </buttons-component>
    <buttons-component v-else>
      <button-component v-if="!isShowAfisha" type="events" @click="$emit('openAfisha')" />
      <button-component v-else type="map" @click="$emit('openMap')" />
      <button-component type="filter" @click="$emit('openFilter')" >
        <template #suffix><span v-if="isChangedFilter" class="change-icon" /></template>
      </button-component>
    </buttons-component>
    <div class="image">
      <experience-image :experience="Event"/>
      <div class="cover">
        <button class="btn back" @click="$emit('close')"><BackIcon /></button>
        <button class="btn share" @click="copyShare"><ShareIcon /></button>
      </div>
    </div>
    <div class="body">
      <div class="title">{{ Event.title }}</div>

      <div class="attrs">
        <div class="item rating">
          <StarIcon />
          <span class="text">{{ Event.topFloat() }}</span>
        </div>
        <div class="item category">
          <span v-html="Event.category.iconSvgHtml"/>
          <span class="text">{{ Event.category.name }}</span>
        </div>
        <div class="item age" v-if="false">
          <span class="text">{{ Event.age }}</span>
        </div>
      </div>

      <div class="description">
        {{ final_desc }}
        <span v-if="Event.desc.length > numDescCharts" class="showMoreButton" @click="showFullDesc = true">{{ $t('map:more') }}</span>
      </div>

      <div class="calendar" v-if="publishDate">
        <CalendarIcon width="18" height="18" />
        <span class="text">{{ publishDate }}</span>
      </div>

      <div class="address" v-if="false">
        <LocationIcon />
        {{ Event.address }}
        <span class="distance" v-if="Event.direction">
          <v-icon v-if="Event.direction.travelMode">{{ Event.direction.travelMode === 'WALKING' ? 'mdi-walk' : 'mdi-car' }}</v-icon>
          {{ Event.direction.distance }} ≈ {{ Event.direction.duration }}
        </span>
      </div>

      <a class="ticket" target="_blank" :href="Event.url" @click="logTransition" v-if="Event.url">
        <div class="source">
          <template v-if="final_price">
            <div class="title">{{ Event.supplier }}</div>
            <div class="desc">{{ $t('map:On sale') }}</div>
          </template>
          <template v-else>
            <div class="title">{{ $t('map:Go to site') }}</div>
          </template>
        </div>
        <div class="price">
          <template v-if="final_price">
            <span class="prefix">{{ $t('map:from') }}</span>
            {{ final_price }}
          </template>
        </div>
        <ArrowIcon stroke="#F5366F"/>
      </a>

      <a class="transfer" target="_blank" :href="getTransferUrl(Event)" v-if="false">
        <div class="logo">
          <img :src="'/resource/img/get-transfer.png'" alt="Get Transfer">
        </div>
        <div class="text">
          {{ $t('map:Book a trip') }}

          <ArrowIcon stroke="#F5366F" style="margin: 0 0 0 1em"/>
        </div>
      </a>

      <div class="details" v-if="Event.publish_date || Event.address">
        <div class="item title">{{ $t('map:Details') }}</div>
        <div class="item" v-if="publishDate">
          <CalendarIcon width="18" height="18" />
          <span class="text">{{ publishDate }}</span>
        </div>
        <div class="item" v-if="Event.address">
          <LocationIcon width="18" height="18" />
          <span class="text">{{ Event.address }}</span>
        </div>
      </div>

      <div class="static-map-wrap" ref="staticMapWrap">
          <static-map-component :center="Event.lat + ',' + Event.lon" @loadResult="loadMapIcon" />
      </div>
    </div>
    <div class="fullDescWrap" :class="{show: showFullDesc}">
      <button class="btn close" @click="showFullDesc = false"><v-icon class="close-icon">mdi-close</v-icon></button>
      <div class="title">{{ Event.title }}</div>
      <div class="desc">{{ Event.desc }}</div>
    </div>
  </div>
</template>

<script>
import BackIcon from '@/component/icons/BackIcon'
import ShareIcon from '@/component/icons/ShareIcon'
import StarIcon from '@/component/icons/StarIcon'
import CalendarIcon from '@/component/icons/CalendarIcon'
import LocationIcon from '@/component/icons/LocationIcon'
import ArrowIcon from '@/component/icons/ArrowIcon'
import ButtonsComponent from "@/component/element/ButtonsComponent.vue";
import ButtonComponent from "@/component/element/ButtonComponent.vue";
import {isMobile} from "mobile-device-detect";
import ExperienceImage from "@/component/mapV2/experience/ExperienceImage.vue";
import StaticMapComponent from "@/component/element/google/staticMap/StaticMapComponent.vue";
import getMarkerIcon from "@/component/mapV2/marker_map_event/getMarkerIcon";
import Experience from "@/class/client/Experience";

export default {
  name: "ExperienceCard",
  components: {
    StaticMapComponent,
    ExperienceImage,
    ButtonComponent,
    ButtonsComponent,
    BackIcon,
    ShareIcon,
    StarIcon,
    CalendarIcon,
    LocationIcon,
    ArrowIcon,
  },
  props: {
    Event: Experience,
    getTransferUrl: Function,
    isShowCard: Boolean,
    isChangedFilter: Boolean,
    isShowAfisha: Boolean,
    extraOptionGetTransfer: Boolean
  },
  watch: {
    isShowCard: function(value) {
      value && Analytic.logFirebaseEvent(Analytic.eventsList.card.card_description_open);
      value || (this.showFullDesc = false)
    },
  },
  computed: {
    publishDate() {
      let data = moment(this.Event.publish_date)

      if (!data.isValid()) return ''

      return data.format('DD MMMM, dddd')
    },
    final_price() {
      return this.Event.price ? this.Event.priceStr : ''
    },
    final_desc() {
      let sliced = this.Event.desc.slice(0, this.numDescCharts)

      return sliced.length < this.Event.desc.length ? sliced + '...' : sliced;
    },
    currencySymbol() {
      return Currency.getSymbolByName(this.Event.currency || 'USD')
    },
  },
  methods: {
    logTransition(){
      const event_type = this.Event.event_source === Analytic.cardOpenFromOptions.list ? Analytic.eventsList.card.card_list_transition : Analytic.eventsList.card.card_map_transition;
      Analytic.logFirebaseEvent(event_type, {id: this.Event.id, title:this.Event.title, category:this.Event.category.name, category_id:this.Event.category.name, url:this.Event.url, supplier:this.Event.supplier})
    },
    getMarkerIcon,
    isMobile() {
      return isMobile
    },
    copyShare() {
      global.share.copyShareLink(this.Event.id, this.Event.type === 'area' ? 'venue' : 'event')
      Analytic.logFirebaseEvent(Analytic.eventsList.links.button_share_click);
    },
    loadMapIcon() {
      if (!this.Event) return
      this.$nextTick(() => {
        let experience = {...this.Event}
        experience.isActive = false
        experience.type = this.Event.type
        if (this.icon) this.icon.remove()
        this.icon = getMarkerIcon(experience).createIcon()
        this.$refs?.staticMapWrap?.append(this.icon)
      })
    }

  },
  data() {
    return {
      numDescCharts: 160,
      showFullDesc: false,
      icon: undefined
    }
  },
}
</script>

<style scoped lang="scss">
  .experience-card {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 100000;
    overflow: auto;

    .image {
      height: 250px;
      position: relative;

      & .v-image {
        height: 100%;
        width: 100%;
      }

      .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1em;

        .btn {
          width: 35px;
          height: 35px;
          padding: 0;
          border-radius: 100%;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      }
    }

    .body {
      padding: 1em;

      & > .title {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 31px;
        color: #021430;
      }

      .attrs {
        color: #707070;
        fill: #707070;
        padding: 1em 0;
        display: flex;
        align-items: center;

        .item {
          display: flex;
          align-items: center;
          line-height: 1;
        }

        .rating .text,
        .category .text {
          margin-left: 5px;
        }

        .category {
          margin-left: 20px;
          text-transform: capitalize;

          /deep/ svg {
            width: 30px;
            height: 30px;
          }
        }

        .age {
          justify-content: end;
          width: 100%;

          .text {
            display: inline-block;
            border: 1px solid #707070;
            border-radius: 5px;
            padding: 5px;
          }
        }
      }

      .description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;

        .showMoreButton {
          cursor: pointer;
          font-weight: bold;
          color: #707070;
        }
      }

      .calendar {
        margin-top: 1.5em;
        display: flex;
        align-items: center;

        .text {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .address {
        margin-top: 1.5em;

        .text {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .ticket {
        display: flex;
        align-items: center;
        margin-top: 1.5em;
        height: 65px;
        background: #FAFAFA;
        border-radius: 10px;
        font-style: normal;
        line-height: 1;
        padding: 1em;
        font-weight: 500;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

        .source {
          .title {
            font-weight: 600;
            font-size: 16px;

            &:first-letter {
              text-transform: uppercase;
            }
          }

          .desc {
            margin-top: .3em;
            font-size: 13px;
            color: #707070;
          }
        }

        .price {
          margin: 0 1em 0 auto;
          font-size: 19px;
          font-weight: 600;

          .prefix {
            font-size: 14px;
            color: #707070;
          }
        }
      }

      .transfer {
        margin-top: 1.5em;
        height: 55px;
        display: flex;
        align-items: center;

        .logo {
          background-color: #FE6902;
          width: 89px;
          height: 100%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .text {
          width: calc(100% - 89px);
          border: 1px solid #EBEBEB;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-left: none;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 1em 0 0;
          justify-content: end;
        }
      }

      .details {
        background: #FAFAFA;
        border-radius: 10px;
        margin-top: 1.5em;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

        .title {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #707070;
        }

        .item {
          border-bottom: 1px solid #EBEBEB;
          min-height: 50px;
          padding: .5em 0 .5em 15px;
          display: flex;
          align-items: center;

          .text {
            margin-left: 5px;
            width: fit-content;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }

      .static-map-wrap {
        position: relative;

        .static-map {
          margin: 1em 0;
        }

        /deep/ .leaflet-marker-icon {
          top: 50%;
          left: 50%;
          transform: translate(0, 0);
        }
      }
    }

    .fullDescWrap {
      position: sticky;
      height: 0;
      width: 100%;
      bottom: 0;
      background: white;
      transition: all .5s;
      overflow: hidden;

      &.show {
        height: 100%;
        padding: 1em;
        overflow: auto;
      }

      .btn {
        width: 35px;
        height: 35px;
        padding: 0;
        border-radius: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        box-shadow: 0 0 3px rgba(0,0,0,0.1);

        .close-icon {
          font-size: 1.3em;
          line-height: 0;
        }
      }

      .title {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 1.3em;
        margin-top: 1em;
        color: #021430;
      }

      .desc {
        margin-top: 2em;
      }
    }
  }
</style>