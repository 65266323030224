export default class BaseClass {
    /** @type ?Client */
    client

    constructor(client) {
        this.client = client
    }

    methodError() {
        throw new Error('Method not implemented')
    }

    propertyError() {
        throw new Error('Property not implemented')
    }
}