<template>
  <IconBase v-if="label === 'FunTam'" view-box="0 0 17 16" icon-name="close" :width="17" :height="16">
    <path
        d="M0.093201 2.1386C0.0432534 1.895 0.217911 1.66216 0.46968 1.63667L16.276 0.0367268C16.4605 0.0180428 16.6141 0.174233 16.5885 0.35466L15.1176 10.7247C15.0882 10.9328 14.9069 11.0877 14.6929 11.0877H10.6828C10.6513 11.0877 10.6206 11.098 10.5956 11.1168L4.77489 15.5124C4.56068 15.6741 4.25856 15.4808 4.32281 15.2232L4.91729 12.8387C4.96138 12.6618 4.82499 12.4912 4.63956 12.4912H2.56721C2.36295 12.4912 2.18704 12.3498 2.14674 12.1533L0.093201 2.1386Z"
        fill="#D72A48"
    />
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "LabelIcon",
  props: {
    label: [String, Boolean]
  },
  components: {
    IconBase
  }
}
</script>

<style scoped>

</style>