export default class Markers {
    #sizes = ['tiny', 'small', 'mid']
    #scales = [1,2,4]

    size = 'mid'
    color
    label
    scale = 1
    locations

    constructor(options) {
        if (options.size) this.size = options.size
        if (options.color) this.color = options.color
        if (options.label) this.label = options.label
        if (options.scale) this.scale = options.scale
        if (options.locations) this.locations = options.locations
    }

    toString() {
        let params = []

        params.push('size:' + this.size)
        if (this.color) params.push('color:' + this.color)
        if (this.label) params.push('label:' + this.label)
        params.push('scale:' + this.scale)
        this.locations.forEach(item => params.push(item))

        return params.join('|')
    }

    validate() {
        let errors = []

        if (!this.#sizes.includes(this.size)) errors.push('Значение свойства size не поддерживается')
        if (this.label && this.label.length > 1) errors.push('Свойство label должно иметь один символ')
        if (!this.#scales.includes(this.scale)) errors.push('Значение свойства scale не поддерживается')
        if (!this.locations || !Array.isArray(this.locations) || !this.locations.length) errors.push('Свойство locations должно быть заполненным массивом')

        if (errors.length) {
            console.debug(errors)
            return false
        }

        return true
    }
}