import { render, staticRenderFns } from "./ExperienceCard.vue?vue&type=template&id=639f1699&scoped=true"
import script from "./ExperienceCard.vue?vue&type=script&lang=js"
export * from "./ExperienceCard.vue?vue&type=script&lang=js"
import style0 from "./ExperienceCard.vue?vue&type=style&index=0&id=639f1699&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "639f1699",
  null
  
)

export default component.exports