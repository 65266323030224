<template name="component-name">
  <div class="iw-select">
    <select
      class="iw-select__select"
      name="iw-select"
      :value="value"
      @change="$emit('change', $event.target.value)
    ">
      <option
        class="iw-select__option"
        v-for="(item, index) in list"
        :key="index + item"
        :value="index"
      >
        <span v-if="item">{{ item }}</span>
        <span v-else>{{ format(index) }}</span>
      </option>
    </select>
  </div>
</template>

// <iw-select class="test" :list="{'ru': 'Russian', 'us': 'United States', 'en': ''}" :value="'ru'" @change="someFunction($event)"></iw-select>
<script>
export default {
  props: {
    list: Object,
    value: [String, Number],
    format: {
      type: Function,
      default: i => i,
    }
  },
}
</script>

<style lang="scss">
.iw-select {
  position: relative;
  width: 100%;
  &__select {
    position: relative;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    border: 1px solid var(--color-905);
    border-radius: 20px;
    background: #fff;
    appearance: none;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px lightgray;
    }
  }

  &::before {
    z-index: 100;
    content: '\25bc';
    position: absolute;
    pointer-events: none;
    color: #82888a;
    top: 0;
    bottom: 1px;
    line-height: 1;
    padding-top: .7em;
    right: 0;
    width: 2em;
    text-align: center;
    -webkit-transform: scale(.84,.42);
    transform: scale(.84,.42);
  }
  &__option {
    // text-transform: uppercase;
  }
}
</style>
