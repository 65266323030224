<template>
  <div class="iw-custom-popup">
    <button class="btn close" @click="experience.setActive(false)"><v-icon class="close-icon">mdi-close</v-icon></button>
    <div v-on:click="$emit('openCard', experience)" style="cursor: pointer">
      <div class="iw-custom-popup__wrapper-header-main">

        <header class="iw-custom-popup__header">
        </header>

        <main class="iw-custom-popup__main" v-if="this.experience.isActive">
          <div class="iw-custom-popup__hotel-name">{{experience.title}}</div>
          <div class="iw-custom-popup__room-type description">{{final_desc}}</div>
          <div class="iw-custom-popup__room-type v3" v-if="experience.direction" style="margin-top: 1em">
            <v-icon v-if="experience.direction.travelMode">{{ travelIcon }}</v-icon> {{experience.direction.duration}} - {{experience.direction.distance}}
          </div>
        </main>
        <div class="iw-custom-popup__background">
          <experience-image :experience="experience" style="width: 100%; height: 100%" emoji-size="5em" />
        </div>
      </div>

      <footer class="iw-custom-popup__footer" >
        <div class="iw-custom-popup__link">
          <span style="padding-right:5px;">{{ this.experience.price === 0 || this.experience.price === "0" ? '' : $t('map:from') }}</span>
          <span style="font-size: 16px; padding-right: 5px; font-weight: 900; color:#000;" v-set-free-color="!experience.price">{{ final_price }}</span>
          <span style="padding-right: 20px;">{{supplier_str}}</span>
          <a class="btn-tickets" :href="experienceUrl" v-if="experienceUrl" target="_blank" :style="getTicketsButtonStyle()"
          @click="logTransition"
          >
            {{ this.experience.price || this.experience.isType('event') ? $t('map:Tickets') : $t('map:Look') }}
            <ArrowIcon class="svg" stroke="inherit" height="9" />
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/component/icons/ArrowIcon'
import Experience from "@/class/client/Experience";
import ExperienceImage from "@/component/mapV2/experience/ExperienceImage.vue";

export default {
  name: "ExperiencePopupDesktop",
  props: {
    experienceUrl: String,
    experience: Experience,
    final_price: String,
    supplier_str: String,
  },
  components: {
    ExperienceImage,
    ArrowIcon
  },
  computed: {
    travelIcon() {
      return this.experience.direction.travelMode === 'WALKING' ? 'mdi-walk' : 'mdi-car'
    },
    final_desc() {
      const charactersNum = 63

      let result = this.experience.descShort.trim(),
          lastSpaceIndex

      if (result.length > charactersNum) {
        result = result.slice(0, charactersNum) + '...'
        lastSpaceIndex = result.lastIndexOf(' ')
        result = result.slice(0, lastSpaceIndex) + '...'
      }

      return result
    }
  },
  methods: {
    logTransition(){
      Analytic.logFirebaseEvent(Analytic.eventsList.map.map_popup_transition, {id: this.experience.id, title:this.experience.title, category:this.experience.category.name, category_id:this.experience.category.name, url:this.experience.url, supplier:this.experience.supplier})
    },
    getTicketsButtonStyle() {
      let styleDefault,
          styleBrand

      if (this.experience.price === 0 || this.experience.price === "0")
        return {
          '--borderColor': 'var(--color-partner-identity)',
          '--backgroundColor': 'var(--color-partner-identity)',
          '--color': '#ffffff',
          '--stroke': '#ffffff',
          '--borderColor-hover': 'var(--color-partner-identity)',
          '--backgroundColor-hover': '#ffffff',
          '--color-hover': 'var(--color-partner-identity)',
          '--stroke-hover': 'var(--color-partner-identity)',
        }

      styleDefault = {
        '--borderColor': '#F5366F',
        '--backgroundColor': '#F5366F',
        '--color': '#ffffff',
        '--stroke': '#ffffff',
        '--borderColor-hover': '#F5366F',
        '--backgroundColor-hover': '#ffffff',
        '--color-hover': '#F5366F',
        '--stroke-hover': '#F5366F',
      }

      styleBrand =this.$store.getters.objectValueMapping({
        '--borderColor': 'bgColor',
        '--backgroundColor': 'bgColor',
        '--color': 'fontColor',
        '--stroke': 'fontColor',
        '--borderColor-hover': 'bgColor',
        '--backgroundColor-hover': 'fontColor',
        '--color-hover': 'bgColor',
        '--stroke-hover': 'bgColor',
      })

      return Object.assign(styleDefault, styleBrand)
    },
  }
}
</script>

<style lang="scss" scoped>
  .iw-custom-popup .close.btn {
    position: absolute;
    left: 100%;
    top: 10px;
    width: 30px;
    height: 40px;
    padding: 0;
    background: var(--color-905);
    color: #000;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &:hover {
      background: var(--color-903);

      .close-icon {
        color: #fff;
      }
    }

    .close-icon {
      color: #000;
    }
  }

  .iw-custom-popup__main {
    .description {
      hyphens: auto;
    }

    & .v-icon {
      font-size: inherit;
      vertical-align: unset;
      color: inherit;

      &::after {
        width: auto;
        height: auto;
      }
    }
  }

  .iw-custom-popup__footer {
    .btn-tickets {
      border-color: var(--borderColor);
      background-color: var(--backgroundColor);
      color: var(--color) !important;
      border-radius: 5px;
      padding: 5px 5px 5px 5px;
      margin-left: auto;
      border: 1px solid;

      &:hover {
        border-color: var(--borderColor-hover);
        background-color: var(--backgroundColor-hover);
        color: var(--color-hover) !important;

        & /deep/ .svg {
          stroke: var(--stroke-hover);
        }
      }

      & /deep/ .svg {
        stroke: var(--stroke);
        //margin: 0 0 0 1em;
      }
    }
  }
</style>