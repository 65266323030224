<template>
  <v-img
      v-if="experience.photo_small !== experience.NO_IMAGE || !experience.emoji"
      :src="experience.photo_small"
      @error="experience.photo_small = experience.NO_IMAGE"
  ></v-img>
  <div v-else class="icon" :style="{fontSize: emojiSize}">{{ experience.emoji }}</div>
</template>

<script>
import Experience from "@/class/client/Experience";

export default {
  name: "ExperienceImage",
  props: {
    experience: {
      type: Experience,
      required: true
    },
    emojiSize: {
      type: String,
      default: '8em'
    }
  },
}
</script>

<style scoped lang="scss">
.icon {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #EBEBEB;
}
</style>