<template>
  <IconBase :icon-color="iconColor" view-box="0 0 16 16" icon-name="location" :width="width" :height="height">
      <path d="M2.41336 5.65992C3.7267 -0.113413 12.28 -0.106746 13.5867 5.66659C14.3534 9.05325 12.2467 11.9199 10.4 13.6933C9.06003 14.9866 6.94003 14.9866 5.59336 13.6933C3.75336 11.9199 1.6467 9.04659 2.41336 5.65992Z" stroke="#292D32"/>
      <path d="M6.16669 7.66667L7.16669 8.66667L9.83335 6" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "LocationIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    iconColor: {
      type: String,
      default: 'none'
    }
  }
}
</script>

<style scoped>

</style>