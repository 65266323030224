<template>
  <div class="main-popup">
    {{ event.name }}
  </div>
</template>

<script>
  export default {
    props: {
      event: Object,
    },
    computed:{}
  }
</script>

<style lang="scss">
  .main-popup {
    background: #fff;
    padding: 10px;
  }
</style>

