<template>

  <LMarker
    :lat-lng="[poi.lat, poi.lon]"
    :icon="icon"
    :visible_disable="'true'"
    @add="$nextTick(() => initMarker($event) )"
  >
      <LPopup
        @add="$nextTick(() => initMarkerPopup($event) )"
        :options="{
          width: 130,
          minWidth: 130,
          maxWidth: 230,
          className: 'popupIwHotel',
          autoPan: false,
          autoPanPadding: [0,0],
          offset: [0, -35],
        }"
      >
        <PoiPopup :poi="poi"/>
      </LPopup>
      <!-- TODO: круг -->
      <!-- TODO: маршрут -->

  </LMarker>

</template>

<script>

  import IwMarkerLibrary from "@/component/mapV2/IwMarkerLibrary";

  import { LMarker, LPopup,  } from "vue2-leaflet";
  import { latLng, icon } from "leaflet";
  import PoiPopup from "@/component/mapV2/poi/PoiPopup";
  import getPoiMarker from "@/component/mapV2/poi/function/getPoiMarker";

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LMarker, LPopup,
      PoiPopup
    },

    props: {
      poi: Object,
    },
    data () {
      return {
        iwMarker: new IwMarkerLibrary()
      }
    },
    methods: {
      initMarker(event){
        this.iwMarker.initMarker(event);
      },
      initMarkerPopup(event){
        this.iwMarker.initMarkerPopup(event);
      },
    },
    computed:{
      icon(){
        return getPoiMarker(
          this.poi,
          {}
        );
      }
    }

  }
</script>

