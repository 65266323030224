import BaseClass from "./BaseClass";

export default class Experience extends BaseClass {
    NO_IMAGE = '/resource/img/no_image.jpg'
    isShare = false
    isActive = false
    _errorCategory = {
        id: 0,
        color: 'red',
        description: '',
        iconSvgHtml: `<svg width="16" height="16" viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg"><path d="M3.83323 14.2C3.73323 14.2778 3.63601 14.2806 3.54156 14.2083C3.44712 14.1361 3.41656 14.0389 3.44989 13.9167L4.76656 9.60001L1.29989 7.11668C1.19989 7.05001 1.16934 6.95835 1.20823 6.84168C1.24712 6.72501 1.32767 6.66668 1.44989 6.66668H5.73323L7.0999 2.13335C7.12212 2.07779 7.15267 2.03335 7.19156 2.00001C7.23045 1.96668 7.27767 1.95001 7.33323 1.95001C7.38878 1.95001 7.43601 1.96668 7.4749 2.00001C7.51378 2.03335 7.54434 2.07779 7.56656 2.13335L8.93323 6.66668H13.2166C13.3388 6.66668 13.4193 6.72501 13.4582 6.84168C13.4971 6.95835 13.4666 7.05001 13.3666 7.11668L9.89989 9.60001L11.2166 13.9167C11.2499 14.0389 11.2193 14.1361 11.1249 14.2083C11.0304 14.2806 10.9332 14.2778 10.8332 14.2L7.33323 11.5333L3.83323 14.2ZM5.04989 11.95L7.33323 10.2167L9.61656 11.95L8.69989 9.06668L10.7999 7.70001H8.28323L7.33323 4.81668L6.38323 7.70001H3.86656L5.96656 9.06668L5.04989 11.95ZM13.2666 14.1833L12.5166 13.6L11.4499 10.1333L14.0166 8.30001H14.9499C15.0721 8.30001 15.1527 8.35835 15.1916 8.47501C15.2305 8.59168 15.1999 8.68335 15.0999 8.75001L12.6166 10.5333L13.6666 13.9167C13.6999 14.0389 13.6666 14.1333 13.5666 14.2C13.4666 14.2667 13.3666 14.2611 13.2666 14.1833ZM9.93323 5.33335L9.43323 3.63335L9.88323 2.13335C9.90545 2.07779 9.93601 2.03335 9.97489 2.00001C10.0138 1.96668 10.061 1.95001 10.1166 1.95001C10.1721 1.95001 10.2221 1.96668 10.2666 2.00001C10.311 2.03335 10.3443 2.07779 10.3666 2.13335L11.3332 5.33335H9.93323Z" /></svg>`,
        name: '',
        lang: 'en'
    }
    emojis = {
        7: ['👨‍👩‍👦', '👨‍👩‍👧', '👨‍👩‍👧‍👦', '👨‍👩‍👦‍👦'], //family
        2: ['🎭', '🗣', '🩰', '🎻'], //theater
        3: ['🚵🏻', '🏅', '🤽🏼', '🏂', '⛸'], //sport
        4: ['💃', '🎶', '🎹', '🎧', '👩🏼‍🎤'], //music
        5: ['🎨', '🖼', '🏛'], //museum
        6: ['☕️', '🍔', '🍰', '🍕', '🌭'], //food
        8: ['🧑🏼‍🏫', '🧑🏼‍🎓', '🧑🏼‍💻', '✍🏼', '📚'], //education
        9: ['👨🏻‍💼', '👨🏻‍💻', '💼', '📊', '📑'], //business
        10: ['🚏', '🚌', '🛣', '🎤'], //bus
        11: ['🍷', '🍺', '🍸', '🍻', '🍾'], //bar
        12: ['🎧'], //audio-guides
        13: ['🚀', '🚲', '🛴'], //activity
        14: ['🛒', '🛍', '🏬', '🤑'], //shop
        15: ['⛴', '🛳', '🎤'], //cruise
        16: ['🤳', '📸', '📷'], //insta spot
        1: ['🗺', '🗽', '🌉', '🎤'], //tour
        0: ['❌']
    }

    emoji
    id
    /**
     * @type {{
     *  id: number,
     *  name: string,
     *  color: string,
     *  icon: string,
     *  iconSvg: string,
     *  iconSvgHtml: string
     * }}
     */
    category
    /**
     * @type {{coordinates: [lon:number,lat:number]}}
     */
    coordinates
    price
    priceStr
    type
    title = ''
    description = ''
    descShort = ''
    address = ''
    link
    direction
    image
    rating
    dateStart
    provider
    categoryId

    setActive(isActive) {
        this.isActive = isActive
    }

    setDirection(venue) {
        if (!venue.lat || !venue.lon) return
        this.direction = {
            from: {'lat': this.lat, 'lon': this.lon},
            to: {
                'lat': venue.lat,
                'lon': venue.lon
            },
            distance: '',
            duration: '',
            type: '',
        }
    }

    /** Загрузка доп свойств. Может быть пустым */
    loadData() { return this.methodError() }

    isType(type) {
        return type === this.type
    }

    topFloat() {
        return Number.parseFloat(this.rating !== undefined ? this.rating : 0).toFixed(1)
    }

    get lat() {
        return this.coordinates.coordinates[1]
    }

    get lon() {
        return this.coordinates.coordinates[0]
    }

    get desc() {
        return this.description
    }

    get url() {
        return this.link
    }

    get photo_small() {
        return this.image || this.NO_IMAGE
    }

    get publish_date() {
        return this.dateStart
    }

    get supplier() {
        return this.provider
    }

    get top() {
        return this.rating
    }

    set photo_small(image) {
        this.image = image
    }
}