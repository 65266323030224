export default (hotel, options) => {

  let room = hotel.room ? hotel.room : hotel.rooms[0];

  // get price
  let currency = Currency.getName();
  let price = room.price[currency];

  // if(!price && room.price['USD']){ price = room.price['USD'];  currency = 'USD' }
  // if(!price && room.price['EUR']){ price = room.price['EUR'];  currency = 'EUR' }

  if(!price ){
    for(let _currency in room.price){
      price     = room.price[_currency];
      currency  = _currency;
    }
  }
  if(options.displayPrice === 'nightly'){
    price = price / hotel.nightsInDest;
  }
  price = parseInt(price);

  // get image
  let markerImg = '';
  switch (hotel.provider) {
    case "ratehawk":
      markerImg = '<img class="iw-marker__img" src="/resource/img/map/hotel/hotelRatehawk.png"/>';
      break;
    case "booking":
      markerImg = '<img class="iw-marker__img" src="/resource/img/map/hotel/hotelBooking.svg"/>';
      break;
    case "partner":
      markerImg = '';
      break;
  }


  const getClassCancelation = (hotel) => (hotel.roomWithMinimalPrice && hotel.roomWithMinimalPrice.refundable) ? 'iw-marker--cancelation' : '';


  return L.divIcon({
    className: 'my-div-icon',
    iconSize:     [100, 30],
    iconAnchor:   [50, 32],
    html: `
      <div class="iw-marker iw-marker--${hotel.provider} ${getClassCancelation(hotel)}">
        <a href="${hotel.hotel_url}" class="iw-marker__wrap">
          <div style="opacity: 0">
            ${markerImg}
            <span class="iw-marker__text">${Currency.format(price, currency)}</span>
          </div>
        </a>
        <div class="iw-marker__wrap">
          ${markerImg}
          <span class="iw-marker__text">${Currency.format(price, currency)}</span>
        </div>
      </a>
    `,
  });
}
