<template>
  <div class="iw-custom-popup" @click="$emit('popupClick')">
    <div class="iw-custom-popup__wrapper-header-main">

      <header class="iw-custom-popup__header">
        <template v-if="hotelStars">
          <div class="iw-custom-popup__header-item iw-custom-popup__star">{{hotelStars}}</div>
        </template>
        <template v-if="hotelNightsInDest">
          <div class="iw-custom-popup__header-item iw-custom-popup__header-item--last iw-custom-popup__night">{{hotelNightsInDest}}</div>
        </template>
        <template v-if="hotelRating">
          <div class="iw-custom-popup__header-item iw-custom-popup__rating">{{hotelRating}}</div>
        </template>
        <template v-if="hotelCancelation">
          <div class="iw-custom-popup__header-item iw-custom-popup__cancelation">{{ $t('map:Free cancelation') }}</div>
        </template>
      </header>

      <main class="iw-custom-popup__main">
        <div class="iw-custom-popup__hotel-name">{{hotelName}}</div>
        <div class="iw-custom-popup__room-type">{{hotelRoomType}}</div>

        <div class="iw-custom-popup__wrapper-distance-rooms">
          <div class="iw-custom-popup__distance">{{hotelDistance}}</div>
          <div class="iw-custom-popup__rooms" v-if="hotelRoomCount">{{hotelRoomCount}} {{$t('map:ROOMS LEFT')}}</div>
        </div>
      </main>
      <div class="iw-custom-popup__background" :style="{background: hotelBackground}"></div>
    </div>

    <footer class="iw-custom-popup__footer" v-if="hotelPrice">
      <div class="iw-custom-popup__link">
        {{hotelPrice}}
      </div>
    </footer>
  </div>
</template>

<script>
  const format = (rating) => {
    function isNumber(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    if(rating && isNumber(rating) ){
      return ''+ parseFloat(rating).toFixed(1)
    }
    return '';
  };

  export default {
    props: {
      hotel: Object,
    },
    computed:{
      hotelStars       (){ return this.hotel.stars > 0 ? this.hotel.stars : false; },
      hotelNightsInDest(){ return this.hotel.nightsInDest ? this.hotel.nightsInDest : false; },
      hotelRating      (){ return this.hotel.rating ? format(this.hotel.rating) : false; },
      hotelCancelation (){ return this.hotel.roomWithMinimalPrice && this.hotel.roomWithMinimalPrice.refundable; },
      hotelName        (){ return this.hotel.name; },
      hotelRoomType    (){
        switch (this.hotel.provider) {
          case "booking":
            return this.hotel.roomWithMinimalPrice.name;
        }
        return false;
      },
      hotelPrice       (){
        switch (this.hotel.provider) {
          case "booking":
            return Currency.format( parseInt( this.hotel.roomWithMinimalPrice.price[Currency.getName()] ) ) + ' ' + this.$t('map:total from Booking');
          case "ratehawk":
            return Currency.format( parseInt( this.hotel.roomWithMinimalPrice.price[Currency.getName()] ) ) + ' ' + this.$t('map:Total stay at');
          case "partner":
            return  this.$t('map:Starting at') + ' ' + Currency.format(this.hotel.price, this.hotel.currency) + ' ' + this.$t('map:for 1 night');
        }
        return false;
      },
      hotelDistance    (){
        const makeText = (direction) => {
          const distance = Math.round(direction.distance / 1000) + 'km' || '';
          const duration = Math.round(direction.duration / 60) + 'min' || '';
          const type = direction.travelMode === 'WALKING' ? 'walking' : 'driving'

          return `${duration} ${type} - ${distance}`;
        };
        return this.hotel.direction && this.hotel.direction.distance ? makeText(this.hotel.direction) : '';
      },
      hotelRoomCount   (){ return (this.hotel.rooms && this.hotel.rooms.length > 0) ? this.hotel.rooms.length : false; },
      hotelBackground  (){ return 'hotelBackground '; },
    }
  }
</script>

<style lang="scss">

</style>
