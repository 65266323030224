<template>
  <IconBase :icon-color="iconColor" view-box="0 0 6 11" icon-name="arrow" :width="width" :height="height">
      <path opacity="0.7" d="M1 10L4.70455 6.29545C5.14205 5.85795 5.14205 5.14205 4.70455 4.70455L1 1" :stroke="stroke" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "ArrowIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 6
    },
    height: {
      type: [Number, String],
      default: 11
    },
    iconColor: {
      type: String,
      default: 'none'
    },
    stroke: {
      type: String,
      default: '#FC6921'
    }
  }
}
</script>

<style scoped>

</style>