<template>
<div>
  <LMarker
    :lat-lng="[event.venue.lat, event.venue.lon]"
    :icon="icon"
    :visible_disable="'true'"
    @add="$nextTick(() => initMarker($event) )"
  >
      <LPopup
        @add="$nextTick(() => initMarkerPopup($event) )"
        :options="{
          // width: 300,
          // minWidth: 300,
          // maxWidth: 300,
          className: 'centralEvent',
          autoPan: false,
          autoPanPadding: [0,0],
          offset: [0, -25],
          closeButton: false
        }"
      >
        <EventPopup :event="event"/>
        
      </LPopup>
  </LMarker>
  <!-- Скрытый радиус -->
  <LCircle v-if="false"
    :lat-lng="[event.venue.lat, event.venue.lon]"
    :radius="1000"
    :stroke="false"
  />
</div>

</template>

<script>

  import IwMarkerLibrary from "@/component/mapV2/IwMarkerLibrary";

  import { LMarker, LPopup, LCircle  } from "vue2-leaflet";
  import { latLng, icon } from "leaflet";
  import EventPopup from "@/component/mapV2/event/EventPopup";
  import getEventMarker from "@/component/mapV2/event/function/getEventMarker";

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LMarker, LPopup, EventPopup,
      LCircle
    },

    props: {
      event: Object,
    },
    data () {
      return {
        iwMarker: new IwMarkerLibrary()
      }
    },
    methods: {
      initMarker(event){
        this.iwMarker.initMarker(event);
        //this.iwMarker.openMarkerDetails();
      },
      initMarkerPopup(event){
        this.iwMarker.initMarkerPopup(event);
      },
    },
    computed:{
      icon(){
        return getEventMarker(
          this.event,
          {}
        );
      }
    }

  }
</script>

<style lang="scss">

</style>