<template>
    <v-bottom-sheet
        v-model="isShow"
        hide-overlay
        persistent
        no-click-animation
    >
      <v-sheet>
        <div ref="content">{{ $nextTick(() => append(bottomSheetContent)) }}</div>
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "bottomSheet",
  computed: mapGetters(['bottomSheetContent']),
  watch: {
    bottomSheetContent: function (newContent) {
      this.isShow = !!newContent
    }
  },
  methods: {
    append(newContent) {
      if (!newContent) return
      this.$refs.content.firstChild.remove()
      this.$refs.content.append(newContent)
    }
  },
  data() {
    return {
      isShow: false,
      content: ''
    }
  }
}
</script>

<style scoped lang="scss">

/deep/ .v-overlay,
.v-dialog__content {
  z-index: 99999 !important;
}
</style>