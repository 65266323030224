<template>
  <transition name="fade" appear>
    <div class="iw-popup" v-if="visibleLocal">
      <h4 class="iw-popup__title" v-if="title">{{ title }}</h4>
      <div class="iw-popup__button-close" @click="close">✕</div>

      <slot></slot>

      <button v-if="applyButton" class="iw-popup__button" @click="apply">Ok</button>
    </div>
  </transition>
</template>

<docs>
  <popup class="offer-map__popup--covid"
    :visible="isOpenPopup"
    :title="$t('map:Settings')"
    :applyButton="true"
    @close="isOpenPopup = false"
    @apply="isOpenPopup = false"
  />
</docs>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      title: String,
      applyButton:  {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        visibleLocal: this.visible,
      };
    },
    methods: {
      close() {
        this.$emit('close');
        this.visibleLocal = false;
      },
      apply() {
        this.$emit('apply');
        this.visibleLocal = false;
      }
    },
    watch: {
      visible(value) {
        this.visibleLocal = value;
      }
    }
  };
</script>

<style lang="scss">
.iw-popup {
  position: relative;
  z-index: 20;
  color: var(--color-902);

  min-width: 200px;
  padding-bottom: 13px;

  border-radius: 3px;
  background: #FFFFFF;
  box-shadow: 0 0 30px 20px rgba(0, 0, 0, .25);

  &__title {
    margin: 0;
    padding: 8px 15px 0;

    font-size: 12px;
    font-weight: bold;
  }

  &__button {
    display: block;

    width: calc(100% - 64px);
    height: 30px;
    margin: 0 32px;

    border-radius: 35px;
    background: var(--color-901);
  }

  &__button-close {
    position: absolute;
    z-index: 30;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    cursor: pointer;

    color: #9F9F9F;

    font-size: 14px;
  }
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
