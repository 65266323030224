<template>
  <div class="second-settings">
    <div class="second-settings__col">
      <iw-button v-if="true"
                 class="second-settings__item second-settings__item--fs"
                 :style="this.$store.getters.objectValueMapping({color: 'fontColor', backgroundColor: 'bgColor'})"
                 v-log.click="{name: 'Map Full Screen'}"
                 v-tooltip="{content: $t('mapTooltip:Zoom'), placement: 'left-center'}">
        <a :href="link" target="_blank"><svg-fs/></a>
      </iw-button>

      <iw-button class="second-settings__item second-settings__item--plus"
                 :style="this.$store.getters.objectValueMapping({color: 'fontColor', backgroundColor: 'bgColor'})"
                 @click="$emit('zoomIn')"  v-log.click="{name: 'Map Zoom In'}"
                 v-tooltip="{content: $t('mapTooltip:Zoom in') , placement: 'left-center'}">
        <svg-plus/>
      </iw-button>

      <iw-button class="second-settings__item second-settings__item--minus"
                 :style="this.$store.getters.objectValueMapping({color: 'fontColor', backgroundColor: 'bgColor'})"
                 @click="$emit('zoomOut')"
                 v-log.click="{name: 'Map Zoom Out'}"
                 v-tooltip="{content: $t('mapTooltip:Zoom out'), placement: 'left-center'}" >
        <svg-minus/>
      </iw-button>
      <!--   Скрытые кнопки   -->
      <iw-button
        v-if="false"
        class="second-settings__item second-settings__item--curr-lang"
        @click="$emit('openPopup')"
        v-log.click="{name: 'Map Language'}"
        v-tooltip="{content: $t('mapTooltip:Language and currency change'), placement: 'left-center'}"
      >
        <span>{{ caps(language) }}</span><span>{{ caps(currency) }}</span>
      </iw-button>
      <iw-button v-if="false" class="second-settings__item second-settings__item--edit "  v-log.click="{name: 'Map Edit'}" v-tooltip="{content: $t('mapTooltip:Widget code edit'), placement: 'left-center'}"><a :href="linkEditGeneratedCode" target="_blank"><svg-edit/></a></iw-button>
    </div>
  </div>
</template>

<script>
import iwButton from "@/component/element/button";
import svgFs from "./icons/fs";
import svgPlus from "./icons/plus";
import svgMinus from "./icons/minus";
import svgEdit from "./icons/edit";

export default {
  components: {
    iwButton,
    svgFs,
    svgPlus,
    svgMinus,
    svgEdit,
  },
  mounted() {
  },
  props: {
    currency: String,
    language: String,
  },
  methods: {
    caps: (str) => str ? str.toUpperCase() : '',
  },
  computed: {
    link() {
      return window.location.origin + this.$route.fullPath;
    },
    linkEditGeneratedCode() {

      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
      }

      return CONST.url.widgetEditServerBase + '/generate/widget' + '?' + encodeQueryData(this.$route.query);
    }
  }
}
</script>

<style lang="scss">
.second-settings {
  display: flex;

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-start;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__item {
    width: 40px;
    height: 40px;
    padding: 0;

    border: none;
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);

    font-size: 20px;

    &--curr-lang {
      width: unset;

      font-size: inherit;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .iw-svg {
      width: 100%;
      height: 100%;
    }

    .iw-svg__fill {
      fill: #ACACAC;
    }

    &--fs {
      .iw-svg {
        width: 27px;
        height: 27px;
      }
    }

    &--plus {
      .iw-svg {
        width: 20px;
        height: 20px;
      }
    }

    &--minus {
      .iw-svg {
        width: 20px;
        height: 2px;
      }
    }
    &--edit {
      .iw-svg {
        width: 22px;
        height: 22px;

        stroke: #ACACAC;
        stroke-width: 10;
      }
    }

    &--curr-lang {
      width: 40px;
      height: 50px;
      display: flex;
      flex-direction: column;
      padding: 8px 5px;
      font-size: 13px;
      span {
        margin-bottom: 3px;
      }
    }
  }
}
</style>
