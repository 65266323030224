<template>
  <div class="formCurrLang">
    <div class="formCurrLang__item">
      <div class="formCurrLang__label">{{ $t('map:Currency') }}</div>
      <iw-select2 class="formCurrLang__select" :list="currencyList" :value="currency" @change="setCurrency"/>
    </div>
    <div class="formCurrLang__item">
      <div class="formCurrLang__label">{{ $t('map:Language') }}</div>
      <iw-select2 class="formCurrLang__select" :list="languageList" :value="language" :format="i => i.toUpperCase()" @change="setLanguage"/>
    </div>
  </div>
</template>

<script>
// import iwSelect from '@/component/uiKit/select';
import iwSelect2 from '@/component/element/select2';
export default {
  components: {
    // iwSelect,
    iwSelect2,
  },
  props: {
    // currency: String,
    // currencyList: Array,
    // language: String,
    // languageList: Array,
  },
  methods: {
    setCurrency(curr) {
      Currency.set(curr);
    },
    setLanguage(lang) {
      Language.set(lang);

      // Reload 
      this.$router.go();
    }
  },
  computed: {
    currency: () => Currency.getName(),
    currencyList: () => Currency.getCurrencyList().reduce((acc, item) => {
      acc[item] = item;
      return acc;      
    }, {}),
    language: () => Language.getName(),
    languageList: () => Language.getLanguageList(),
  }
}
</script>

<style lang="scss">
.formCurrLang {
  width: 100%;
  padding: 20px 10px 5px;

  &__item:not(:last-child) {
    margin-bottom: 15px;
  }

  &__label {
    margin-bottom: 5px;
    font-weight: bold;
  }
}
</style>