<template>
  <IconBase :icon-color="iconColor" view-box="0 0 24 24" icon-name="back" :width="width" :height="height">
    <path d="M15 19.9201L8.48003 13.4001C7.71003 12.6301 7.71003 11.3701 8.48003 10.6001L15 4.08008" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "BackIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'none'
    }
  }
}
</script>

<style scoped>

</style>