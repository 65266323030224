<template>
  <transition :name="isMobile ? 'slide-fade' : ''">
    <div class="filter-wrap" :class="{'mobile': isMobile}">
      <buttons-component v-if="isMobile">
        <button-component type="close" @click="closeFilter" />
        <button-component type="reset" @click="resetFilter"/>
        <button-component type="apply" :class="{blue: isChange}" @click="applyFilter(); $emit('closeFilter')" />
      </buttons-component>
      <div class="filter" :style="getFilterStyle">
        <div class="filter-item types">
          <div class="label">
            {{ $t('map:Show') }}
          </div>
          <div class="list">
            <label class="list-item" :class="{'checked' : form.type === item.value}"
                   v-for="(item, key) in types" :key="key">
              <input v-show="false" class="item-input" type="radio"
                     :value="item.value" v-model="form.type" @change="changeFilter('Filter conten type change', item)">

              <span>{{ item[lang] }}</span>

              <v-icon v-show="form.type === item.value">mdi-close</v-icon>

            </label>
          </div>
        </div>
        <div class="filter-item categories">
          <div class="label">
            {{ $t('map:Category') }}
          </div>
          <div class="list">
            <label class="list-item" :class="{'checked' : form.eventCategories.includes(item.value)}"
                   v-for="(item, key) in eventCategoryOptions" :key="'event-' + key">
              <input v-show="false" class="item-input" type="checkbox" v-model="form.eventCategories"
                     :value="item.value" @change="changeFilter('Filter category change', {'current_category': item, 'all_selected_category': form.eventCategories})">

              <span>{{ item.name }}</span>

              <v-icon v-show="form.eventCategories.includes(item.value)">mdi-close</v-icon>

            </label>
            <label class="list-item" :class="{'checked' : form.venueCategories.includes(item.value)}"
                   v-for="(item, key) in venueCategoryOptions" :key="'venue-' + key">
              <input v-show="false" class="item-input" type="checkbox" v-model="form.venueCategories"
                     :value="item.value" @change="changeFilter('Filter venue category change', {'current_venue_category': item, 'all_selected_venue_category': form.venueCategories})">

              <span>{{ item.name }}</span>

              <v-icon v-show="form.venueCategories.includes(item.value)">mdi-close</v-icon>

            </label>
          </div>
        </div>
        <div class="filter-item prices">
          <div class="label">
            {{ $t('map:Price') }}
          </div>
          <div class="list">
            <label class="list-item" :class="{'checked' : form.prices.includes(item.value)}"
                   v-for="(item, key) in prices" :key="key">
              <input v-show="false" class="item-input" type="checkbox"
                     :value="item.value" v-model="form.prices" @change="changeFilter('Filter price change', {'current_venue_category': item, 'all_selected_venue_category': form.venueCategories})">

              <span>{{ item[lang] }}</span>

              <v-icon v-show="form.prices.includes(item.value)">mdi-close</v-icon>

            </label>
          </div>
        </div>
        <div class="filter-item distance" v-if="false">
          <div class="label">
            {{ $t('map:Distance') }}
          </div>
          <div class="list">
            <label class="list-item" :class="{'checked' : form.distance === item.value}"
                   v-for="(item, key) in distanceList" :key="key">
              <input v-show="false" class="item-input" type="radio" v-model="form.distance"
                     :value="item.value" @click="form.distance === item.value && (form.distance = '')">

              {{ item[lang] }}

              <v-icon v-show="form.distance === item.value">mdi-close</v-icon>

            </label>
          </div>
        </div>
      </div>


      <buttons-component>
        <button-component
            v-show="isChange"
            type="apply"
            class="blue apply"
            @click="applyFilter(); !isMobile && $emit('openAfisha'); $emit('closeFilter')"
        />
      </buttons-component>
    </div>
  </transition>
</template>

<script>
import ButtonComponent from "@/component/element/ButtonComponent.vue";
import ButtonsComponent from "@/component/element/ButtonsComponent.vue";

export default {
  name: "FilterComponent",
  props: {
    isMobile: Boolean,
    filter: Object,
    cleanFilter: Object,
    prices: Array,
    eventCategories: {
      type: Array,
      default: () => []
    },
    venueCategories: {
      type: Array,
      default: () => []
    },
  },
  components: {
    ButtonsComponent,
    ButtonComponent,
  },
  computed: {
    eventCategoryOptions() {
      const allowedTypes = ['', 'event']

      if (!allowedTypes.includes(this.form.type)) return []
      if (this.eventCategories.length === 0) return []

      return this.eventCategories.map(category => ({
        name: category.name,
        value: category.id,
      }));
    },
    venueCategoryOptions() {
      const allowedTypes = ['', 'venue']

      if (!allowedTypes.includes(this.form.type)) return []
      if (this.venueCategories.length === 0) return []

      return this.venueCategories.map(category => ({
        name: category.name,
        value: category.id,
      }));
    },
    getFilterStyle() {
      let styleDefault,
          styleBrand

      styleDefault = {
        '--borderColor-checked': '#F5366F',
        '--backgroundColor-checked': '#F5366F',
        '--color-checked': '#ffffff',
        '--borderColor-checked-hover': '#F5366F',
        '--backgroundColor-checked-hover': '#ffffff',
        '--color-checked-hover': '#F5366F',
      }

      styleBrand =this.$store.getters.objectValueMapping({
        '--borderColor-checked': 'bgColor',
        '--backgroundColor-checked': 'bgColor',
        '--color-checked': 'fontColor',
        '--borderColor-checked-hover': 'fontColor',
        '--backgroundColor-checked-hover': 'fontColor',
        '--color-checked-hover': 'bgColor',
      })

      return Object.assign(styleDefault, styleBrand)
    }
  },
  methods: {
    applyFilter() {
      this.$emit('applyFilter', Object.assign({}, this.form))
      this.isChange = false
      this.isChanged = JSON.stringify(this.form) !== JSON.stringify(this.cleanFilter)
      this.$emit('isChanged', this.isChanged)
      Analytic.logFirebaseEvent(Analytic.eventsList.filter.filter_apply);
    },
    resetFilter() {
      this.form = {...{}, ...this.cleanFilter}
      Analytic.logFirebaseEvent(Analytic.eventsList.filter.filter_clear);
    },
    closeFilter() {
      this.setDefaultFilterData()
      this.$emit('closeFilter')
      this.$nextTick(() => this.isChange = false)
      Analytic.logFirebaseEvent(Analytic.eventsList.filter.filter_close);
    },
    setDefaultFilterData() {
      this.form = Object.assign({}, this.filter)
    },
    changeFilter(type, item) {
      //TODO refactor the type retrieval
      Analytic.logFirebaseEvent(type, item);
    }
  },
  data() {
    return {
      lang: Language.getName(),
      isChange: false,
      isChanged: false,
      types: [
        {
          ru: 'Все',
          en: 'All',
          value: ''
        },
        {
          ru: 'События',
          en: 'Events',
          value: 'event'
        },
        {
          ru: 'Места',
          en: 'Areas',
          value: 'venue'
        },
      ],
      selectedPrice: '',
      distanceList: [
        {
          ru: 'Рядом',
          en: 'Nearby',
          value: '500',
        },
        {
          ru: 'До 1 км',
          en: 'To 1 km',
          value: '1000',
        },
        {
          ru: 'До 5 км',
          en: 'To 5 km',
          value: '5000',
        },
        {
          ru: 'До 10 км',
          en: 'To 10 km',
          value: '10000',
        },
        {
          ru: 'До 50 км',
          en: 'To 10 km',
          value: '50000',
        },
      ],
      selectedDistance: '',
      form: undefined
    }
  },
  created() {
    this.form = Object.assign({}, this.filter)
    this.isChanged = JSON.stringify(this.form) !== JSON.stringify(this.cleanFilter)
  },
  mounted() {
    this.$watch('form', () => {
      this.isChange = true
    }, {deep: true})
  },
  updated(){
    this.$nextTick(function () {
      //Analytic.logFirebaseEvent(Analytic.eventsList.filter.filter_panel_open);
    })
  }
}
</script>

<style lang="scss">
.filter-wrap {
  width: 100%;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  background-color: rgb(255, 255, 255);

  .filter {
    height: 100%;
    overflow: auto;
    padding: 10px 0 0;

    .filter-item {
      margin: 0 5px;
      border: 1px solid #EBEBEB;
      padding: 13px 20px;

      &:not(:last-child) {
        border-bottom: none;
      }

      .label {
        font-weight: 600;
        font-size: 12px;
        color: #9D9FA3;
      }

      .list {
        margin: -2px -10px 0;

        .list-item {
          position: relative;
          //min-width: 94px;
          text-align: center;
          margin: 10px 0 0 10px;
          padding: 8px 24px;
          display: inline-block;
          border: 1px solid #a5a5a5;
          color: #a5a5a5;
          border-radius: 25px;
          cursor: pointer;
          font-weight: 500;
          line-height: 1;
          user-select: none;

          .mdi-close {
            font-size: 21px;
            vertical-align: text-top;
            color: var(--color-checked);
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            margin-left: 3px;
          }

          &.checked {
            background-color: var(--backgroundColor-checked);
            border-color: var(--borderColor-checked);
            color: var(--color-checked);

            padding: 8px 34px 8px 14px;
          }

          &:hover {
            background-color: var(--backgroundColor-checked-hover);
            border-color: var(--borderColor-checked-hover);
            color: var(--color-checked-hover);

            .mdi-close {
              color: var(--color-checked-hover);
            }
          }

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &.prices {
        .list {
          .list-item:first-child {
            border: 1px solid var(--color-partner-identity);
            color: var(--color-partner-identity);

            .mdi-close {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .button.apply {
    width: 95%;
    margin-bottom: 10px;
  }
}

.filter-wrap.mobile {
  position: absolute;
  z-index: 3001;

  .list-item {
    min-width: 100px;

  }

  .button.apply {
    margin: 20px auto;
  }
}
</style>