var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LMarker',{attrs:{"lat-lng":[_vm.experience.lat, _vm.experience.lon],"icon":_vm.icon,"visible_disable":'true'},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarker($event) )},"popupopen":_vm.openPopupHandler,"popupclose":function($event){return _vm.experience.setActive(false)}}},[_c('LPopup',{ref:"popup",attrs:{"options":{
        minWidth: 270,
        maxWidth: 340,
        className: 'popupIwHotel ' + (_vm.isMobile ? 'hidden' : ''),
        autoPan: false,
        autoPanPadding: [0,0],
        offset: [0, -12],
      }},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarkerPopup($event) )}}},[_c('ExperiencePopup',{attrs:{"experience":_vm.experience,"mainevent":_vm.mainevent,"isMobile":_vm.isMobile,"settings":_vm.settings},on:{"renderSheetContent":function($event){_vm.sheetContent = $event},"openCard":function($event){return _vm.$emit('openCard', $event)},"popupClose":function($event){return _vm.experience.setActive(false)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }