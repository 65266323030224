<template>
  <IconBase :icon-color="iconColor" view-box="0 0 16 16" icon-name="calendar" :width="width" :height="height">
    <path d="M5.33331 1.33325V3.33325" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6667 1.33325V3.33325" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.33331 6.06006H13.6666" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 5.66659V11.3333C14 13.3333 13 14.6666 10.6667 14.6666H5.33333C3 14.6666 2 13.3333 2 11.3333V5.66659C2 3.66659 3 2.33325 5.33333 2.33325H10.6667C13 2.33325 14 3.66659 14 5.66659Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4632 9.13338H10.4691" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4632 11.1334H10.4691" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.99697 9.13338H8.00296" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.99697 11.1334H8.00296" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.52956 9.13338H5.53555" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.52956 11.1334H5.53555" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "CalendarIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    iconColor: {
      type: String,
      default: 'none'
    }
  }
}
</script>

<style scoped>

</style>