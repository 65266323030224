<template>
  <IconBase :icon-color="iconColor" view-box="0 0 24 24" icon-name="share" :width="width" :height="height">
    <path d="M9.32001 6.49994L11.88 3.93994L14.44 6.49994" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.88 14.18V4.01001" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "ShareIcon",
  components: {
    IconBase
  },
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    },
    iconColor: {
      type: String,
      default: 'none'
    }
  }
}
</script>

<style scoped>

</style>