var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LMarker',{attrs:{"lat-lng":[_vm.experience.lat, _vm.experience.lon],"icon":_vm.icon,"visible_disable":'true'},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarker($event) )}}},[_c('LPopup',{attrs:{"options":{
        width: 130,
        minWidth: 130,
        maxWidth: 270,
        className: 'popupIwHotel',
        autoPan: false,
        autoPanPadding: [0,0],
        offset: [0, -10],
      }},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarkerPopup($event) )}}},[_c('ExperiencePopup',{attrs:{"experience":_vm.experience,"mainevent":_vm.mainevent}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }