var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('LMarker',{attrs:{"lat-lng":[_vm.event.venue.lat, _vm.event.venue.lon],"icon":_vm.icon,"visible_disable":'true'},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarker($event) )}}},[_c('LPopup',{attrs:{"options":{
          // width: 300,
          // minWidth: 300,
          // maxWidth: 300,
          className: 'centralEvent',
          autoPan: false,
          autoPanPadding: [0,0],
          offset: [0, -25],
          closeButton: false
        }},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarkerPopup($event) )}}},[_c('EventPopup',{attrs:{"event":_vm.event}})],1)],1),(false)?_c('LCircle',{attrs:{"lat-lng":[_vm.event.venue.lat, _vm.event.venue.lon],"radius":1000,"stroke":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }