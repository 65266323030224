<template>

  <LMarker
    :lat-lng="[experience.lat, experience.lon]"
    :icon="icon"
    :visible_disable="'true'"
    @add="$nextTick(() => initMarker($event) )"
  >
      <LPopup
        @add="$nextTick(() => initMarkerPopup($event) )"
        :options="{
          width: 130,
          minWidth: 130,
          maxWidth: 270,
          className: 'popupIwHotel',
          autoPan: false,
          autoPanPadding: [0,0],
          offset: [0, -10],
        }"
      >
        <ExperiencePopup 
          :experience="experience"
          :mainevent=mainevent
        />
      </LPopup>
      <!-- TODO: круг -->
      <!-- TODO: маршрут -->

  </LMarker>

</template>

<script>

  import IwMarkerLibrary from "@/component/mapV2/IwMarkerLibrary";

  import { LMarker, LPopup,  } from "vue2-leaflet";
  import { latLng, icon } from "leaflet";
  import ExperiencePopup from "@/component/mapV2/experience/ExperiencePopup";

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LMarker, LPopup,
      ExperiencePopup
    },

    props: {
      experience: Object,
      mainevent: Object
    },
    data () {
      return {
        iwMarker: new IwMarkerLibrary(this.experience, () => this.$emit("closeAllMarker"))
      }
    },
    methods: {
      initMarker(event){
        this.iwMarker.initMarker(event);
      },
      initMarkerPopup(event){
        this.iwMarker.initMarkerPopup(event);
      },
    },
    computed:{
      icon(){
        const iconBG = this.experience.icon ? `background-image: url('${this.experience.icon}')` : 'background-color: #FFFFFF';
        return L.divIcon({
          iconAnchor: [20, 20],
          iconSize:   [40, 40],
          className: 'marker--custom-poi',
          html: `
            <div class="marker marker--custom-poi">
              <div class="marker__icon" style="${iconBG};"></div>
            </div>
          `
        });
      }

      /*icon(){
        return L.divIcon({
          iconAnchor: [15, 40],
          iconSize:   [30, 40],
          className: 'iw-marker',
          html: `
            <div class="iw-marker-poi">
              <div class="iw-marker-poi__icon iw-marker-poi__icon--general"></div>              
            </div>
          `
        });
      }*/
    }

  }
</script>

