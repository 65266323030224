<template>

  <LMarker
    :lat-lng="[experience.lat, experience.lon]"
    :icon="icon"
    :visible_disable="'true'"
    @add="$nextTick(() => initMarker($event) )"
    @popupopen="openPopupHandler"
    @popupclose="experience.setActive(false)"
  >
      <LPopup
        ref="popup"
        @add="$nextTick(() => initMarkerPopup($event) )"
        :options="{
          minWidth: 270,
          maxWidth: 340,
          className: 'popupIwHotel ' + (isMobile ? 'hidden' : ''),
          autoPan: false,
          autoPanPadding: [0,0],
          offset: [0, -12],
        }"
      >
        <ExperiencePopup
          @renderSheetContent="sheetContent = $event"
          @openCard="$emit('openCard', $event)"
          @popupClose="experience.setActive(false)"
          :experience="experience"
          :mainevent="mainevent"
          :isMobile="isMobile"
          :settings="settings"
        />

      </LPopup>
      <!-- TODO: круг -->
      <!-- TODO: маршрут -->

  </LMarker>

</template>

<script>

  import IwMarkerLibrary from "@/component/mapV2/IwMarkerLibrary";

  import { LMarker, LPopup,  } from "vue2-leaflet";
  import ExperiencePopup from "@/component/mapV2/experience/ExperiencePopup";
  import { mapActions } from 'vuex'
  import Experience from "@/class/client/Experience";
  import getMarkerIcon from "@/component/mapV2/marker_map_event/getMarkerIcon";

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LMarker, LPopup,
      ExperiencePopup
    },

    props: {
      experience: Experience,
      mainevent: Object,
      settings: Object,
      isMobile: Boolean,
      shareId: [Number, String]
    },
    data () {
      return {
        iwMarker: new IwMarkerLibrary(
            this.experience,
            () => this.$emit("closeAllMarker"),
            ),
        sheetContent: '',
        Marker: {},
        areaBrandingStyles: (() => {
          let objectStyles = this.$store.getters.objectValueMapping({ 'border-color': 'fontColor', 'background-color': 'bgColor' })

          return Object.entries(objectStyles).map(([k, v]) => `${k}:${v}`).join(';')
        })()
      }
    },
    methods: {
      ...mapActions(['openBottomSheet', 'closeBottomSheet']),
      // TODO: Убрать костыль, когда появится ссылка в пульсе
      async openPopupHandler() {
        await this.experience.loadData()
        this.experience.setActive(true)
        this.$refs.popup.mapObject.update()
      },
      initMarker(event){
        this.Marker = event.target
        this.experience.iwMarker = this.iwMarker
        this.iwMarker.initMarker(event);
        this.Marker.on('click', (event) => {
          if(event.originalEvent && !this.isMobile){
            this.$emit('openCard', this.experience)
          }
        })
        if(this.isActive){
          this.iwMarker.openMarkerDetails();
        }
      },
      initMarkerPopup(event){
        this.iwMarker.initMarkerPopup(event);
      },
      onPopupOpen() {
        if (this.isMobile) this.$nextTick(() => {
          this.experience.loadData()
          this.openBottomSheet(this.sheetContent)
        })

        if (!this.iwMarker.isOpen()) this.iwMarker.openMarkerDetails()
      },
      onPopupClose() {
        if (this.isMobile) this.closeBottomSheet()

        if (this.iwMarker.isOpen()) this.iwMarker.closeMarkerDetails()
      },
    },
    computed:{
      icon(){
        return getMarkerIcon(this.experience)
      },
      isActive() {
        return this.experience.isActive;
      }
    },
    watch: {
      isActive() {
        this.isActive ? this.onPopupOpen() : this.onPopupClose()
      }
    },
    mounted() {
      if (this.isActive) this.$nextTick(() => this.onPopupOpen())
    }

  }
</script>

<style lang="scss">
  .iw-marker--venue {
    position: relative;

    &.marker-active {
      animation: animate_grow 1.5s infinite;

      & .iw-marker-venue-link {
        box-shadow: 0 6px 6px 2px #00000063;
      }
    }

    .iw-marker__img {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-left: -4px;
      padding: 2px;

      svg {
        fill: #FFFFFF;
        width: 100%;
        height: 100%;
      }
    }

    a.iw-marker__wrap {
      position: absolute;
      //left: 0;
      //right: 0;
      //top:0;
      //bottom: 0;
      //z-index: 1;
      min-width: 0;
      pointer-events: none;
      &::after {
        display: none;
      }
      .arrow {
        //background: #4D235B;
        bottom: -4px;
        position: absolute;
        z-index: -1;
        //bottom: -6px;
        left: calc(50% - 3px);

        display: block;

        width: 8px;
        height: 8px;

        content: '';
        transform: rotate(45deg);

        border-radius: 2px;
      }
    }
    div.iw-marker__wrap {
      min-width: 0;
      z-index: 2;
      border: none;
    }

    div.iw-marker__wrap {
      //border: 2px solid #00000000;
      border: none;
      background: #00000000;
    }
    a.iw-marker__wrap {
      border: 1px solid white;
      //border: none;
      background: #4D235B;
    }
    a.iw-marker__wrap:visited {
      //border: 2px solid #a8a8a8;
      border: none;
      background: #9ca299 !important;
    }

    .iw-marker__wrap {
      height: 25px;

      &:hover {
        //background: #3F1B4B;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .2);

        /*&::after {
          background: #2a1435;
        }*/
      }
    }
  }

  .area-div-icon {
    border: 2px solid #FFFFFF;
    background-color: #0932e1;
    border-radius: 100%;
    height: 100%;
    width: 100%;
  }

  @keyframes animate_grow {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    50% {
      transform: scale(1.3, 1.3) translateY(-15%);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
</style>