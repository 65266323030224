var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LMarker',{attrs:{"lat-lng":[_vm.hotel.latitude, _vm.hotel.longitude],"icon":_vm.icon},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarker($event) )}}},[_c('LPopup',{attrs:{"options":{
      width: 240,
      minWidth: 240,
      maxWidth: 240,
      className: 'popupIwHotel',
      autoPan: false,
      autoPanPadding: [0,0],
      offset: [0, -20]
    }},on:{"add":function($event){_vm.$nextTick(() => _vm.initMarkerPopup($event) )}}},[_c('HotelPopup',{attrs:{"hotel":_vm.hotel}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }