<template>
  <LMarker
    :lat-lng="[hotel.latitude, hotel.longitude]"
    :icon="icon"
    @add="$nextTick(() => initMarker($event) )"
  >
    <LPopup
      @add="$nextTick(() => initMarkerPopup($event) )"
      :options="{
        width: 240,
        minWidth: 240,
        maxWidth: 240,
        className: 'popupIwHotel',
        autoPan: false,
        autoPanPadding: [0,0],
        offset: [0, -20]
      }"
    >
      <HotelPopup :hotel="hotel"/>
    </LPopup>
  </LMarker>

</template>

<script>

  import IwMarkerLibrary from "@/component/mapV2/IwMarkerLibrary";

  import { LMarker, LPopup  } from "vue2-leaflet";
  import { latLng, icon } from "leaflet";
  import HotelPopup from "@/component/mapV2/hotel/HotelPopup";
  import getHotelMarker from "@/component/mapV2/hotel/function/getHotelMarker";

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      LMarker, LPopup, HotelPopup
    },

    props: {
      hotel: Object,
      displayPrice: {
        type: String,
        default: 'nightly', // total | nightly
      },
    },
    data () {
      this.option = {
        direction: {
          from: {
            lat: this.hotel.latitude,
            lon: this.hotel.longitude,
          },
          to: {
            lat: this.hotel.eventLocation.lat,
            lon: this.hotel.eventLocation.lng,
          },
          option: {
            travelMode: this.hotel.distanceToEvent < 1000 ? 'WALKING' : 'DRIVING',
          },
          callback: this.initDirection,
        }
      };

      return {
        iwMarker: new IwMarkerLibrary(this.option)
      }
    },
    methods: {
      initMarker(event){
        this.iwMarker.initMarker(event);
      },
      initMarkerPopup(event){
        this.iwMarker.initMarkerPopup(event);
      },
      initDirection(direction){
        this.hotel.direction = direction;
      },
    },
    computed:{
      icon(){
        return getHotelMarker(
          this.hotel,
          {
            displayPrice: this.displayPrice
          }
        );
      }
    }

  }
</script>

