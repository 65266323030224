<template>
  <button :class="classes" @click="$emit('click')">
    <slot>
      {{ content }}
      <CloseIcon v-if="type === 'close'" width="1.5em" height="1.5em" />
    </slot>
    <slot name="suffix"/>
  </button>
</template>

<script>
import CloseIcon from "@/component/icons/CloseIcon.vue";

export default {
  name: "ButtonComponent",
  components: {CloseIcon},
  props: {
    type: String
  },
  computed: {
    classes() {
      return {
        button: true,
        filter: this.type === 'filter',
        'animation-flare': this.type === 'filter',
        close: this.type === 'close'
      }
    },
    content() {
      if (this.type === 'filter') return this.$t('map:Filters')
      if (this.type === 'reset') return this.$t('map:Reset')
      if (this.type === 'apply') return this.$t('map:Apply')
      if (this.type === 'events') return this.$t('map:Events')
      if (this.type === 'map') return this.$t('map:Map')
      return ''
    },
  }
}
</script>

<style scoped lang="scss">
.button {
  height: 2.666em;
  padding: 0;
  margin: 0 6px;
  border: 1px solid #BEC2C9;
  border-radius: 40px;

  &:hover:not(.blue):not(.filter) {
    background-color: var(--backgroundColor-hover);
    color: var(--color-hover);
    border-color: var(--borderColor-hover);
  }

  &.close {
    line-height: 0;
    padding: 0;
    border-radius: 100%;
    min-width: 2.666em;
    width: 2.666em;
  }

  &.blue {
    background-color: var(--color-901);
    color: #fff;
    border-color: #fff !important;
  }

  &.filter {
    background-color: var(--color-partner-identity);
    color: #ffffff;
    border-color: #ffffff !important;
  }
}
</style>