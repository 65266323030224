import { render, staticRenderFns } from "./select2.vue?vue&type=template&id=30207e75&name=component-name"
import script from "./select2.vue?vue&type=script&lang=js"
export * from "./select2.vue?vue&type=script&lang=js"
import style0 from "./select2.vue?vue&type=style&index=0&id=30207e75&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./select2.vue?vue&type=custom&index=0&blockType=iw-select&class=test&%3Alist=%7B'ru'%3A%20'Russian'%2C%20'us'%3A%20'United%20States'%2C%20'en'%3A%20''%7D&%3Avalue='ru'&%40change=someFunction(%24event)"
if (typeof block0 === 'function') block0(component)

export default component.exports