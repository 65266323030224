<template>
  <v-card ref="card" class="card" :class="card.classes" :style="card.styles" v-touch:start.stop="onGrabbedLever"  @click="openCard">
    <div class="lever">
      <v-divider></v-divider>
    </div>
    <experience-image :experience="experience" emoji-size="5em" />
    <div class="over-image-wrap">
      <v-avatar
          :style="`background: ${experience.category.color}`"
          v-html="experience.category.iconSvgHtml"
      />
      <div class="rating-wrap" v-if="experience.type !== 'area' && experience.top">
        {{ experience.topFloat() }}
        <v-icon>mdi-star</v-icon>
      </div>
    </div>
    <v-card-title ref="title" :style="title.styles">{{ experience.title }}</v-card-title>
    <v-card-text ref="description" :style="description.styles">{{ experience.desc }}</v-card-text>
    <v-card-text>
      <span style="padding-right:5px;" v-if="!(this.experience.price === 0 || this.experience.price === '0')">
        {{ $t('map:from') }}
      </span>
      <span style="padding-right: 5px; font-weight: 900; color:#000;" v-set-free-color="!experience.price">{{ final_price }}</span>
      {{ supplier_str }}
      <span class="distance" v-if="experience.direction && experience.direction.distance">
        <v-icon v-if="experience.direction.travelMode">{{ travelIcon }}</v-icon>
        <span v-if="experience.direction.duration"> {{ experience.direction.duration }} &#8226; </span>
        {{ experience.direction.distance }}
      </span>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="experience.url" class="btn-book btn-tickets"
             target="_blank"
             elevation="0"
             :style="getTicketsButtonStyle()"
             :href="experience.url"
             @click="logTransition"
             @click.stop=""
             
      >
        {{ $t('map:Tickets') }}

        <ArrowIcon class="svg" stroke="inherit" />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ArrowIcon from '@/component/icons/ArrowIcon'
import Experience from "@/class/client/Experience";
import ExperienceImage from "@/component/mapV2/experience/ExperienceImage.vue";

export default {
  name: "ExperiencePopupMobile",
  props: {
    experience: Experience,
    final_price: String,
    supplier_str: String,
    mainevent: Object,
    settings: Object
  },
  components: {
    ExperienceImage,
    ArrowIcon
  },
  methods: {
    logTransition(){
      Analytic.logFirebaseEvent(Analytic.eventsList.map.map_popup_transition, {id: this.experience.id, title:this.experience.title, category:this.experience.category.name, category_id:this.experience.category.name, url:this.experience.url, supplier:this.experience.supplier})
    },
    openCard() {
      if (!this.touchActive)
        this.$emit('openCard', this.experience)
    },
    getTransferUrl() {
      let baseUrl = 'https://gettransfer.com/transfers/new',
          date = (new Date()),
          dateString = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'),
          timeString = String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0'),
          urlData = {
            // 'transfer[from][name]': this.experience.title,
            'transfer[from][point]': '(' + this.mainevent.venue.lat + ',' + this.mainevent.venue.lon + ')',
            'transfer[to][point]': '(' + this.experience.lat + ',' + this.experience.lon + ')',
            // 'transfer[to][name]': this.experience.title.trim(),
            'transfer[trip_to][date]': dateString + ' ' + timeString,
            // 'transfer[name_sign]': 'John'
            'utm_campaign': CONST.labelSettings.en.label.toLowerCase(),
            'utm_source': 'web',
            'utm_medium': 'partner_promo',
          }

      return baseUrl + '?' + new URLSearchParams(urlData).toString()
    },
    getTicketsButtonStyle() {
      let styleDefault,
          styleBrand

      if (this.experience.price === 0 || this.experience.price === "0")
        return {
          '--borderColor': 'var(--color-partner-identity)',
          '--backgroundColor': 'var(--color-partner-identity)',
          '--color': '#ffffff',
          '--stroke': '#ffffff',
          '--borderColor-hover': 'var(--color-partner-identity)',
          '--backgroundColor-hover': '#ffffff',
          '--color-hover': 'var(--color-partner-identity)',
          '--stroke-hover': 'var(--color-partner-identity)',
        }

      styleDefault = {
        '--borderColor': '#F5366F',
        '--backgroundColor': '#F5366F',
        '--color': '#ffffff',
        '--stroke': '#ffffff',
        '--borderColor-hover': '#F5366F',
        '--backgroundColor-hover': '#ffffff',
        '--color-hover': '#F5366F',
        '--stroke-hover': '#F5366F',
      }

      styleBrand =this.$store.getters.objectValueMapping({
        '--borderColor': 'bgColor',
        '--backgroundColor': 'bgColor',
        '--color': 'fontColor',
        '--stroke': 'fontColor',
        '--borderColor-hover': 'bgColor',
        '--backgroundColor-hover': 'fontColor',
        '--color-hover': 'bgColor',
        '--stroke-hover': 'bgColor',
      })

      return Object.assign(styleDefault, styleBrand)
    },

    onGrabbedLever(event) {
      let firstTouch = event.touches[0]

      this.lever.startY = firstTouch.clientY
      this.title.styles.maxHeight = this.$refs.title.clientHeight + 'px'
      this.description.styles.maxHeight = this.$refs.description.clientHeight + 'px'
      this.title.styles.whiteSpace = 'initial'
      this.description.styles.whiteSpace = 'initial'

      if (!this.card.classes.full) {
        // this.title.minHeight = this.$refs.title.clientHeight
        // this.description.minHeight = this.$refs.description.clientHeight
        // this.card.defaultHeight = this.$refs.card.$el.clientHeight
      } else {
        this.title.maxHeight = this.$refs.title.clientHeight
        this.description.maxHeight = this.$refs.description.clientHeight
      }

      document.addEventListener('touchmove', this.onLeverDrag)
      document.addEventListener('touchend', this.onLeverDrop)
    },
    onLeverDrag(event) {
      // let diff,
      //     newTitleHeight,
      //     newDescriptionHeight,
      let    firstTouch = event.touches[0]

      this.touchActive = true

      if (this.card.styles.height === 'initial') {
        this.card.clientHeight = this.$refs.card.$el.clientHeight + (this.lever.startY - firstTouch.clientY)
        this.card.styles.height = this.card.clientHeight  + 'px'
      } else {
        this.card.styles.height = this.card.clientHeight + (this.lever.startY - firstTouch.clientY)  + 'px'
      }
      // if (!this.card.classes.full) {
      //   if (firstTouch.clientY < this.lever.startY) {
          // diff = (this.lever.startY - firstTouch.clientY) / 2
          // this.card.styles.height = 'initial'
          // this.title.styles.maxHeight = this.title.minHeight + diff + 'px'
          // this.description.styles.maxHeight = this.description.minHeight + diff + 'px'
        // } else {
          // diff = firstTouch.clientY - this.lever.startY
          // this.card.styles.height = this.card.defaultHeight - diff + 'px'
      //   }
      // }

      // if (this.card.classes.full && firstTouch.clientY > this.lever.startY) {
        // diff = (firstTouch.clientY - this.lever.startY) / 2
        // newTitleHeight = this.title.maxHeight - diff
        // newDescriptionHeight = this.description.maxHeight - diff
        // this.title.styles.maxHeight = newTitleHeight > this.title.minHeight ? newTitleHeight + 'px' : this.title.minHeight + 'px'
        // this.description.styles.maxHeight =
        //     newDescriptionHeight > this.description.minHeight ? newDescriptionHeight + 'px' : this.description.minHeight + 'px'
      // }
    },
    onLeverDrop(event) {
      let firstTouch = event.changedTouches[0],
          saveZone = 20

      this.touchActive = false
      // if (!this.card.classes.full) {
        if (firstTouch.clientY < this.lever.startY - saveZone) {
          this.openCard()
          this.card.styles.height = 'initial'
          // this.title.styles.whiteSpace = this.description.styles.whiteSpace = 'initial'
          // this.title.styles.maxHeight = this.description.styles.maxHeight = '20em'
          // this.card.classes.full = true;
        } else {
          // this.title.styles.whiteSpace = this.description.styles.whiteSpace = 'nowrap'
          // this.title.styles.maxHeight = this.description.styles.maxHeight = 'initial'
          // this.card.classes.full = false;

          if (firstTouch.clientY > this.lever.startY + saveZone) {
            this.$emit('popupClose')
          }

          this.card.styles.height = 'initial'
        }
      // } else if (firstTouch.clientY > this.lever.startY + saveZone) {
      //   this.title.styles.whiteSpace = this.description.styles.whiteSpace = 'nowrap'
      //   this.title.styles.maxHeight = this.description.styles.maxHeight = 'initial'
      //   this.card.classes.full = false;
      // }

      this.detachLeverEvents()
    },
    detachLeverEvents() {
      this.lever.startY = 0
      document.removeEventListener('touchmove', this.onLeverDrag)
      document.removeEventListener('touchend', this.onLeverDrop)
    }
  },
  computed: {
    travelIcon() {
      return this.experience.direction.travelMode === 'WALKING' ? 'mdi-walk' : 'mdi-car'
    }
  },
  data() {
    return {
      lever: {
        startY: 0,
      },
      title: {
        minHeight: 0,
        maxHeight: 0,
        styles: {
          whiteSpace: 'nowrap',
          maxHeight: 'initial',
        },
      },
      description: {
        minHeight: 0,
        maxHeight: 0,
        styles: {
          whiteSpace: 'nowrap',
          maxHeight: 'initial',
        },
      },
      card: {
        defaultHeight: 0,
        classes: {
          full: false
        },
        styles: {
          height: 'initial'
        },
        clientHeight: undefined
      },
      touchActive: false
    }
  },
  mounted() {
    this.$emit('renderSheetContent', this.$el)
  }
}
</script>

<style lang="scss" scoped>
.card {
  font-size: 1rem;

  & > .lever {
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.3em 3em;
    position: absolute;
    z-index: 1;
    background: #fff;
    border-radius: 30px !important;

    & .v-divider {
      width: 20px;
      max-width: 20px;
      border-width: 1px;
    }
  }

  & > .v-image {
    max-height: 8em;
    min-height: 7em;
  }

  & > .over-image-wrap {
    position: absolute;
    top: .5em;
    left: .5em;
    font-size: .7em;

    & .v-avatar {
      padding: .3em;
      border: 2px solid #fff;
      height: 3.4em !important;
      min-width: 3.4em !important;
      width: 3.4em !important;

      /deep/ svg {
        fill: #FFFFFF;
      }
    }

    & .rating-wrap {
      color: #efd04a;
      font-size: 1.7em;
      font-weight: bold;
      background: #00000042;
      padding: 0.1em;
      line-height: 1;
      margin-top: 6px;

      & .v-icon {
        font-size: 1em;
        margin-left: -.3em;
        color: inherit;
        vertical-align: initial;
      }
    }
  }

  & .v-card__title,
  & .v-card__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
  }

  & .v-card__title {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 1.3rem;
    font-size: 1.3em;
    font-weight: 600;
  }

  & .v-card__text {
    font-size: .9em;
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: auto;
  }

  & .distance {
    float: right;

    & .v-icon {
      font-size: 17px;
      vertical-align: unset;

      &::after {
        width: auto;
        height: auto;
      }
    }
  }

  & .v-card__actions {
    overflow: auto;
    align-items: flex-end;
    padding: 12px 16px;

    & > :not(:first-child) {
      margin-left: 15px;
    }

    & .v-btn {
      border-radius: 12px;
      margin: 0;
      width: auto;
      height: 40px;
      padding: 0 12px;
      font-size: 14px;
      letter-spacing: initial;
      text-transform: initial;
      background: #f3f6fc;
      color: #196dff;
      overflow: hidden;

      .svg {
        margin-left: 5px;
      }
    }

    & .btn-book {
      background: rgb(237, 74, 108);
      color: white;
      padding: 1.05em 2.8em;
      border: 0;
      width: 175px;

      & /deep/ .v-btn__content {
        align-items: baseline;
      }
    }

    & .btn-get-transfer {
      padding: 0;
      color: #000;
      background: none;
      border: 1px solid #ee732f;

      & .text-wrap {
        padding: 0 6px;
        display: flex;
        align-items: baseline;

        & .text {
          //font-weight: bold;
        }
      }
    }

    & .btn-tickets {
      border-color: var(--borderColor);
      background-color: var(--backgroundColor);
      color: var(--color);
      font-weight: 700;

      &:hover {
        border-color: var(--borderColor-hover);
        background-color: var(--backgroundColor-hover);
        color: var(--color-hover);

        & /deep/ .svg {
          stroke: var(--stroke-hover);
        }
      }

      /deep/ .v-btn__content {
        position: initial;
      }

      & /deep/ .svg {
        stroke: var(--stroke);
        position: absolute;
        right: 0;
        top: 50%;
        margin-right: 1em;
        transform: translate(0, -50%);
      }
    }
  }
}
</style>