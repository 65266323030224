const areaBrandingStyles = (() => {
    let objectStyles = global.VueStore.getters.objectValueMapping({ 'border-color': 'fontColor', 'background-color': 'bgColor' })

    return Object.entries(objectStyles).map(([k, v]) => `${k}:${v}`).join(';')
})()

export default experience => {
    if (experience.type === 'area') return L.divIcon({
        className: 'area-div-icon-wrap',
        iconSize:     [14, 14],
        html: `
            <div class="area-div-icon" style="${ areaBrandingStyles }"></div>
          `
    });
    return L.divIcon({
        className: 'my-div-icon',
        iconSize:     [100 + (!experience.price ? 10 : 0), 30],
        iconAnchor:   [50, 32],
        html: `
            <div class="iw-marker iw-marker--venue ${ experience.isActive ? 'marker-active' : '' }">
              <a  class="iw-marker__wrap iw-marker-venue-link" target="_blank" href="${ experience.url }" style="${ !experience.price ? 'background: var(--color-partner-identity)' : 'background: ' + experience.category.color }">
                <div style="opacity: 0">

                  <span class="iw-marker__img">${ experience.category.iconSvgHtml }</span>
                  <span class="iw-marker__text">${ experience.priceStr }</span>
                </div>
                <div class="arrow" style="background: ${ experience.category.color }"></div>
              </a>
              <div class="iw-marker__wrap" style="background: none !important;">
                  <span class="iw-marker__img" ">${ experience.category.iconSvgHtml }</span>

                <span class="iw-marker__text">${ experience.priceStr }</span>
                <div class="arrow" style="${ 'background: ' + experience.category.color }"></div>
              </div>
            </a>
          `,
    });
}