export default (poi, options) => {

  const tLink = '';
  // const tLink = point.url ? `<a class="iw-marker-poi__link" href="${point.url}" target="_blank"></a>` : `<!-- -->`;

  return L.divIcon({
    iconAnchor: [24, 56],
    iconSize:   [48, 56],
    className: 'iw-marker',
    html: `
      <div class="iw-marker-poi">
        <div class="iw-marker-poi__icon iw-marker-poi__icon--${poi.type}"></div>
        ${tLink}
      </div>
    `
  });
}
