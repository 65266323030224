<template>
  <IconBase view-box="0 0 50 50" icon-name="close" :width="width" :height="height">
    <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"/>
  </IconBase>
</template>

<script>
import IconBase from '@/component/icons/IconBase'

export default {
  name: "CloseIcon",
  props: {
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: [Number, String],
      default: 50
    },
  },
  components: {
    IconBase
  }
}
</script>

<style scoped>

</style>