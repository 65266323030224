<template>
  <div class="buttons-wrap" :style="buttonStyles">
    <slot>
      <template v-if="!isMobile">
        <button-component v-if="!isShowFilter || isShowCard" type="filter" @click="$emit('openFilter')" >
          <template #suffix><span v-if="isChangedFilter" class="change-icon" /></template>
        </button-component>
        <template v-else>
          <button-component type="close" @click="$emit('closeFilter')"><template #suffix/></button-component>
          <button-component type="reset" @click="$emit('resetFilter')" />
          <button-component type="apply" @click="$emit('applyFilter')" :class="{blue: isChangeFilter}" />
        </template>
      </template>
      <template v-else>
        <button-component type="events" @click="$emit('openAfisha')" />
        <button-component type="filter" @click="$emit('openFilter')">
          <template #suffix><span v-if="isChangedFilter" class="change-icon" /></template>
        </button-component>
      </template>
    </slot>
  </div>
</template>

<script>
import ButtonComponent from "@/component/element/ButtonComponent.vue";
export default {
  name: "ButtonsComponent",
  props: {
    isMobile: Boolean,
    isShowFilter: Boolean,
    isChangeFilter: Boolean,
    isChangedFilter: Boolean,
    isShowCard: Boolean,
  },
  components: {ButtonComponent},
  data() {
    return {
      buttonStyles: {
        ...{
          '--color-hover': '#F5366F',
          '--backgroundColor-hover': '#ffffff',
          '--borderColor-hover': '#F5366F'
        },
        ...this.$store.getters.objectValueMapping({
          '--color-hover': 'bgColor',
          '--backgroundColor-hover': 'fontColor',
          '--borderColor-hover': 'bgColor'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.buttons-wrap {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #9D9FA3;
  background-color: #ffffff;
  height: 4.167em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}
</style>