export default (event, options) => {

  const iconBG = event.icon ? `background-image: url('${event.icon}')` : 'background-color: #FFFFFF';
  const icon = L.divIcon({
    iconAnchor: [20, 40],
    iconSize:   [40, 47],
    className: 'marker--event',
    html: `
      <div class="marker marker--event">
        <div class="marker--event-animation"></div>
        <div class="marker__icon" style="${iconBG};"></div>
      </div>
    `
  });
  return icon;
}
