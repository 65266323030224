<template>
  <div>
    <second-settings v-if="showSecondSettings"
                     class="offer-map__second-navigation"
                     :currency="currencySign"
                     :language="language"
                     @openPopup="isOpenPopup = true"
                     @zoomIn="zoomIn"
                     @zoomOut="zoomOut"

    />
    <div v-if="showCTA" class="map__top-side">
      <div class="cta_section">
        <div class="cta_scroll">
          <div><span>{{ $t('map:Explore Events') }} </span><span>{{
              $t('map:Activities Around')
            }}</span><span> {{ cityName }}</span><span> {{ currentDate }}</span><span>{{
              $t('map:Activities Around')
            }}</span><span> {{ cityName }}</span><span> {{ currentDate }}</span></div>
          <div><span>{{ $t('map:Explore Events') }} </span><span>{{
              $t('map:Activities Around')
            }}</span><span> {{ cityName }}</span><span> {{ currentDate }}</span><span>{{
              $t('map:Activities Around')
            }}</span><span> {{ cityName }}</span><span> {{ currentDate }}</span></div>
        </div>
      </div>
    </div>

    <!--  Рекомендации - Отключено в вычисляемых свойствах  -->
    <a :href="recommendationsLink" target="_blank" v-if="isRecommendations">
      <div class="map__top-button">
        {{ $t('map:Recommendations') }}
      </div>
    </a>
    <div class="map__bottom-side">
      <a class="map__bottom-side-item map__bottom-side-item--powered-by left" :href="settings.labelSettings.websiteLink"
         target="_blank" v-html="brandLogo"></a>

      <div class="map__bottom-side-item map__bottom-side-item--weather" v-if="weather[0]">
        <div class="weather" @click="isOpenPopupWeather = !isOpenPopupWeather">
          <div class="weather__icon" :style="{ backgroundImage: 'url(' + averageIconUrl + ')' }"></div>
          <div class="weather__temp">{{ Math.round(averageTemp) }}°C</div>
        </div>
      </div>

      <a class="map__bottom-side-item map__bottom-side-item--powered-by right" :href="settings.labelSettings.websiteLink"
         target="_blank">{{ settings.labelSettings.label }} | @Google</a>
    </div>

    <popup class="w-popup" :applyButton="false" :visible="isOpenPopupWeather" @close="isOpenPopupWeather = false">
      <div class="w-popup__body">
        <div class="w-popup__col" v-for="item in weather" :key="item.date">
          <div class="w-popup__weekday">{{ item.dateMoment.format('ddd') }}</div>
          <div class="w-popup__date">{{ item.dateMoment.format('MM/DD') }}</div>
          <div class="w-popup__icon" :style="{ backgroundImage: 'url(' + iconUrl(item) + ')' }"></div>
          <div class="w-popup__temp">
            <span class="w-popup__temp-span" v-if="item.maxTemp"><small>day</small> <b>{{
                Math.round(item.maxTemp)
              }}°</b></span> &nbsp;
            <span class="w-popup__temp-span" v-if="item.minTemp"><small>night</small> <b>{{
                Math.round(item.minTemp)
              }}°</b> </span>
          </div>
          <div class="w-popup__wind" v-if="item.windspeed && item.windspeed > 0">{{ item.windspeed }} <small>m/s</small>
          </div>
        </div>
      </div>
      <a href="https://weather.com" class="w-popup__link iw-link" target="blank">weather.com</a>
    </popup>

    <popup class="offer-map__popup" :visible="isOpenPopup" :title="$t('map:Settings')" :applyButton="false"
           @close="isOpenPopup = false">
      <form-curr-lang class="offer-map__form"
                      :language="language"
                      :languageList="languageList"
                      @change="changeSecondSettings"
      />
    </popup>

    <template v-if="covidBanner">
      <!--      TODO: fix-->
      <!--      v-log.click="{name:'Map COVID Popup Ok'}-->
      <!--      v-log.click="{name:'Map COVID Popup Close'}"-->
      <popup class="offer-map__popup offer-map__popup--covid" :visible="isOpenPopupCovid">
        <div class="covid-popup">
          <img class="covid-popup__img" src="/resource/img/frame/microbe.png" alt="covid-19" width="16" height="16">
          <div class="covid-popup__text covid-popup__text--bold">
            {{ $t('map:Due to the covid-19 disaster we display free-cancelation hotels only') }}
          </div>
          <div class="covid-popup__text">{{ $t('map:Look out for the free cancelation badges') }}</div>
          <div class="covid-popup__marker">
            <div class="iw-marker iw-marker--cancelation">
              <div class="iw-marker__wrap">
                <span class="iw-marker__text">$ 200</span>
              </div>
            </div>
          </div>
        </div>
      </popup>
    </template>
    <template v-if="false">
      <popup class="offer-map__popup offer-map__popup--app" :visible="true">
        <div class="app-popup">
          APP popup
        </div>
      </popup>
    </template>

  </div>
</template>

<script>
// import isEmpty from 'lodash/isEmpty';

import popup from "@/component/element/popup";
import secondSettings from "@/component/map/secondSettings/secondSettings";
import formCurrLang from "@/component/map/formCurrLang/formCurrLang";

export default {
  components: {
    popup,
    secondSettings,
    formCurrLang
  },
  props: {
    map: Object,

    event: Object,

    hotelList: Array,
    hotelSelected: Object,
    partnerHotels: Array,

    poi: Array,

    eventLogo: String,
    displayPrice: String,
    mapZoom: Number,
    covidBanner: {
      type: Boolean,
      default: true,
    },
    showSecondSettings: {
      type: Boolean,
      default: false,
    },
    showCTA: {
      type: Boolean,
      default: false,
    },
    isMobile: Boolean,
    isShowButtonAfish: Boolean,
    appLink: String,
    settings: Object
  },
  mounted() {
    setTimeout(() => {
      this.isOpenPopupCovid = true;
    }, 1500);
  },
  data() {
    return {
      eventMapMarker: null,
      eventMapCircle: null,
      hotelMapMarkerObj: {},

      isVisibleGetApp: true,

      isOpenPopup: false,
      isOpenPopupApp: true,
      isOpenPopupCovid: false,
      isOpenPopupWeather: false,
    }
  },
  methods: {
    changeSecondSettings({type, value}) {
      this[type] = value;
    },
    zoomIn() {
      this.map.zoomIn();
    },
    zoomOut() {
      this.map.zoomOut();
    },
    iconUrl(day) {
      const code = day.iconCode ? day.iconCode : '32';
      return `https://doc.media.weather.com/products/icons/${code}.png`;
    }
  },
  computed: {
    recommendationsLink() {
      let city = this.event?.venue?.city?.name;
      if (city in this.recommendationsLinkList) {
        return this.recommendationsLinkList[city];
      }
      return "";
    },
    isRecommendations() {
      return false;
      // let city = this.event?.venue?.city?.name;
      // return city in this.recommendationsLinkList;
    },
    cityName() {
      const name = this.event?.venue?.city?.name;
      return name || "";
    },
    currentDate: () => moment().format('D MMMM YYYY'),
    currency: () => Currency.getName(),
    currencyList: () => Currency.getCurrencyList(),
    currencySign: () => Currency.getSymbol(),
    languageList: () => Language.getLanguageList(),
    language: () => Language.getName(),
    weather() {
      return this.$store.getters.weather;
    },
    averageTemp() {
      if (this.weather.length === 0) {
        return false;
      }

      const summ = this.weather.reduce((acc, item) => {
        acc += item.maxTemp;
        return acc;
      }, 0);

      return summ / this.weather.length;
    },
    averageIconUrl() {
      const map = this.weather.map((i) => i.iconCode ? i.iconCode : '32');
      const stat = map.reduce((acc, item) => {
        if (!acc[item]) {
          acc[item] = 0;
        }
        acc[item] += 1;
        return acc;
      }, {});

      const max = Math.max(...Object.values(stat));
      const entries = Object.entries(stat);
      const [code] = entries.find(([code, count]) => count == max);

      return `https://doc.media.weather.com/products/icons/${code}.png`;
    },
    brandLogo() {
      let brandLogoUrl = this.$store.getters.widgetSetting('brandLogo')

      return brandLogoUrl ?`<img src="` + brandLogoUrl + `" alt="logo" class="brand-img">`
          :`<span class="brand-text"></span>`
    }
  },
};

</script>

<style lang="scss">

.map__top-side {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 34px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;

}

.map__top-button {
  position: absolute;
  top: 40px;
  height: 34px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0 5px;
  color: black;
  font-size: 22px;
  line-height: 34px;
  border-radius: 5px;
  left: 5px;
  @media screen and (max-width: 810px) {
    font-size: 16px;
  }
}

.btn-close {
  display: inline-flex;
  position: absolute;
  height: .8em;
  right: 0;
  top: 50%;
  padding: 0 .4rem;
  cursor: pointer;
  border-left: 1px solid;
  font-weight: bold;
  transform: translate(0%, -50%);
  align-items: center;

  & .v-icon {
    color: inherit;
    font-size: 1em;
  }
}

.cta_section {
  position: relative;
  width: 100%;
  font-size: 22px;
  line-height: 34px;
  overflow: hidden;
  @media screen and (max-width: 810px) {
    font-size: 16px;
  }
}

.cta_section .cta_scroll {
  width: 100%;
  display: flex;
}

.cta_section .cta_scroll div span {
  padding-right: 40px;
}

.cta_section .cta_scroll div {
  white-space: nowrap;
  animation: animate 30s linear infinite;
  animation-delay: -30s;
  //padding-right: 30px;
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.cta_section .cta_scroll div:nth-child(2) {
  animation: animate2 30s linear infinite;
  animation-delay: -15s;
}

@keyframes animate2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

/*
.map_cta {
  font-size: 22px;
  line-height: 40px;
  padding-left: 10px;
  @media screen and (max-width: 810px) {
      font-size: 12px;
  }
}

#scroller{
  margin:0;
  padding:0;
  position:relative;
  cursor: pointer;
}

#scroller li{
  float:left;
  list-style-position:inside;
  list-style-type: none;
  height: 18px;
  line-height: 35px;
}

.scrollerWrapper{
  height:28px;
  margin:0px;
  overflow:hidden;
  z-index: 90;
  position: absolute;
  width: 100%;
}

.scroller-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.left-transition {
  transition: left 4s;
}*/

.weather {
  display: flex;
  padding: 5px 10px;
  color: #000000;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 3px;

  &__icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

// weather-widget
.w-popup {
  position: absolute;
  bottom: 40px;
  left: calc(50% - 98px);
  width: 200px;
  z-index: 10000;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  .iw-popup__button-close {
    display: none;
  }

  &__body {
    display: flex;
    justify-content: center;
    // overflow-x: auto;
  }

  &__col {
    padding: 5px 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 10px;
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 3px;
  }

  &__date {
    margin-bottom: 5px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__temp {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-span {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      white-space: nowrap;

      b {
        margin-left: 3px;
      }

      &:first-child {
        margin-right: 3px;
        margin-bottom: 5px;
      }
    }
  }

  &__link {
    position: absolute;
    bottom: 5px;
    font-size: 11px;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}


.offer-map__second-navigation {
  position: absolute;
  z-index: 1000;
  //top: calc(50% - 125px);
  bottom: 5%;
  right: 10px;
}

.offer-map__popup {
  position: absolute;
  z-index: 1000;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.map__bottom-side {
  position: absolute;
  z-index: 3000;
  bottom: 0;

  display: flex;
  align-items: end;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  .map__bottom-side-item--powered-by.left {
    padding: 0;
    background-color: initial;

    .brand-text {
      padding: 2px 4px;
    }

    .brand-img {
      height: 35px;
      max-width: 158px;
    }
  }

  & .btn-show-afisha {
    width: auto;
    text-transform: initial;
    background: #e9e9e9;
    border: 2px solid #808080;
    height: auto;
    font-size: 1.2em;
    margin: 0;
    flex: auto;
  }
}

.map__bottom-side-item {
  display: flex;
  align-items: center;

  margin-top: 3px;
  padding: 2px 4px;

  color: #FFFFFF;
  background: rgba(0, 0, 0, .4);

  font-size: 14px;

  &--weather {
    background: none;
  }
}

// MAP SHADOW
#map::before, #map::after {
  position: absolute;
  z-index: 2000;

  display: block;

  width: 100%;
  height: 1px;

  content: '';
}

#map::before {
  top: -1px;
  box-shadow: 0 0 50px 50px rgba(0, 0, 0, .5);
}

#map::after {
  bottom: -1px;
  box-shadow: 0 0 30px 30px rgba(0, 0, 0, .5);
}

#map .gm-style {
  .gmnoprint, .gm-style-cc {
    display: none;
  }

  div {
    height: 0;
  }
}

#map .leaflet-control-attribution.leaflet-control {
  display: none;
}

.grid-sm {
  .map__bottom-side {
    flex-wrap: nowrap;
  }

  .map__bottom-side-item {
    font-size: 12px;
  }
}

.offer-map__popup--covid {
  bottom: 50px;
  top: unset;
  left: 50px;
  right: unset;
}

.offer-map__popup--app {
  background: #FFFFFF;
  z-index: 10000;
  bottom: 50px;
  top: 50px;
  left: 20px;
  right: 20px;
}


.grid-sm {
  .offer-map__popup--covid {
    left: 5px;
    bottom: 22px;
  }
}

.covid-popup {
  width: 240px;

  &__img {
    margin: 5px 0 5px 5px;
  }

  &__text {
    margin: 0 10px 5px;
    font-size: 12px;
    color: #000000;
  }

  &__text--bold {
    font-weight: bold;
  }

  &__marker {
    margin: 20px 0 25px;
  }

  .iw-marker__text {
    width: 100%;
    text-align: center;
  }
}

</style>
