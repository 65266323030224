const ConvertGoogleDirectionInLatLngArray = (googleResult) => {
  var googleLatLng = googleResult.routes[0].overview_path;
  var latLngArray = [];
  for (let i = 0; i < googleLatLng.length; i++) {
    latLngArray[latLngArray.length] = [googleLatLng[i].lat(), googleLatLng[i].lng()];
  }
  return latLngArray;
};

/*
* getDirectionLibrary
* @return Promise
* resolve Direction
*/
export default (fromCoordinate , toCoordinate, option = {}) => {
  
  if(!option.travelMode){
    option.travelMode = 'DRIVING';
  }
  
  /*option = Object.assign({
    travelMode: 'DRIVING', // 'WALKING' | 'DRIVING'
  }, option )*/

  let request = {
    origin: fromCoordinate.lat + ',' + fromCoordinate.lon,
    destination: toCoordinate.lat + ',' + toCoordinate.lon,
    travelMode: option.travelMode, // distanceToEvent < 1000 ? 'WALKING' : 'DRIVING',
  };

  const directionsService = new google.maps.DirectionsService();

  return new Promise((resolve, reject) => {
    directionsService.route(request, function(result, status) {

      let leg = result.routes[0].legs[0];

      if (status !== google.maps.DirectionsStatus.OK) {
        reject(false);
      }

      let points = ConvertGoogleDirectionInLatLngArray(result);

      const direction = {
        travelMode: request.travelMode,
        distance: leg.distance.value,
        duration: leg.duration.value,
        points,
      }

      resolve(direction);
    });
  })
};
